import { TCardFormCardProps } from "../../components/CardFormCard/CardFormCard";
import red_compra from "../../assets/logos/red_compra.svg";
import visa_electron from "../../assets/logos/visa_electron.svg";
import maestro from "../../assets/logos/maestro.svg";
import webpay from "../../assets/logos/webpay.svg";

type TBrands = Pick<TCardFormCardProps, "logos">;

export const BrandsCardAsync: TBrands = {
  logos: [
    { image: red_compra, value: "redCompra" },
    { image: visa_electron, value: "visaElectron" },
    { image: maestro, value: "maestro" },
  ],
};

export const WebpayCardAsync: TBrands = {
  logos: [{ image: webpay, value: "webpay" }],
};
