import { IAppTranslation } from "../infrastructure/MultiLanguage";

/**
 * Portuguese translation for webcheckout
 */
const notAvailablePortugueseMessage: string =
  "Não foi possível criar a transação. Tente novamente mais tarde ou entre em contato com a empresa.";
const identificationNumber: string = "Número de identificação";

const primeiroNome: string = "Primeiro nome";
const correio: string = "Correio eletrônico";
const card: string = "Pagamento com cartão";
export const portuguese: IAppTranslation = {
  payButton: ["Vai pagar "],
  payButtonWithMount: ["Pagar "],
  pageNotFound: ["Não conseguimos encontrar a página que procura."],
  footer: {
    contacts: [" Contacte-nos"],
    messageFooter: [
      "Ao confirmar uma transacção a partir deste portal, aceitou previamente os Termos e Condições do ",
    ],
    help: ["Precisa de ajuda?"],
    messagePay: [
      "Este pagamento é processado com segurança pela Kushki, um fornecedor de pagamento PCI de Nível 1.",
    ],
    readMore: [" Mais informação"],
    changeLanguage: ["Mudar idioma"],
  },
  confirmMessage: {
    title: ["Quer eliminar esta informação?"],
    message: [
      "Se apagar estes dados, não poderá utilizá-los para efectuar os seus pagamentos rapidamente. Pode guardar novamente esta informação seleccionando a opção ",
    ],
    cancel: ["cancelar"],
    ok: ["Eliminar dados"],
    messageComplete: [
      "Se apagar estes dados, não poderá utilizá-los para efectuar os seus pagamentos rapidamente. Pode guardar novamente esta informação seleccionando a opção na caixa de pagamento.",
    ],
    messageSecond: [" na caixa de pagamento."],
  },
  WrongPasswordless: {
    dataVerification: ["Verificação"],
    securityData: ["Houve muitas tentativas malsucedidas"],
    securityDataMessage: [
      "A verificação não pôde ser concluída porque você falhou em muitas tentativas. Volta a tentar.",
    ],
    securityRememberMessage: [
      "Lembre-se que o valor que foi carregado ou debitado do seu cartão será estornado imediatamente.",
    ],
  },
  purchaseDetail: {
    title: ["Resumo do pagamento"],
    totalToPay: ["Total a pagar"],
    item: ["Produtos"],
    information: ["Informação"],
    breakdown: ["Quebra de preço"],
    paymentDescription: ["Descrição"],
  },
  listPaymentMethods: {
    titlePayMethods: ["Meios de pagamento"],
    titlePaySaved: ["Detalhes de pagamento salvos"],
    titleOtherPay: ["Outros meios de pagamento "],
    titleCardAndDebit: ["Cartão de débito ou de crédito"],
    titleCard: ["Cartão de crédito "],
    titleDebit: ["Cartão de débito "],
    titleCash: ["Dinheiro"],
    titleTransfer: ["Transferência de dinheiro"],
    titleCardDynamic: ["Cartão de crédito ou débito"],
    expiredCardMessage: ["O cartão expirou"],
  },
  card: {
    titleCard: ["Cartão"],
  },
  languages: {
    en: ["Inglês"],
    es: ["Espanhol"],
    pt: ["Português"],
  },
  messageTimeSession: ["Esta sessão expira em "],
  userName: ["Oi "],
  changeTypePay: ["Mudar o metodo de pagamento"],
  checkInformationSave: [
    "Guarde os dados do seu cartão para futuras compras nas lojas integradas Kushki",
  ],
  termsConditions: ["Aceite os termos e condições"],
  messagePCI: [
    "Seus dados estão protegidos e criptografados, em conformidade com a certificação PCI DSS Nível 1.",
  ],
  loadingTCard: {
    mainLine: [
      "Aguarde enquanto redirecionamos você para a página do seu banco. ",
    ],
    secondaryLine: ["Não feche nem saia desta janela."],
  },
  loadingCard: {
    mainLine: ["Espere um momento "],
    secondaryLine: ["Estamos processando seu pagamento"],
  },
  safetyPay: [
    "Insira seu e-mail e clique em “Gerar ordem de pagamento” para receber os dados necessários para efetuar sua transferência",
  ],
  sessionExpired: [
    "Sua sessão expirou devido a um limite de tempo. Por favor faça login novamente.",
  ],
  backStore: ["Regresso à loja"],
  sessionExpiredTitle: ["Sessão expirada."],
  downloadConfirmation: ["Confirmação de download"],
  helpKushkiVerification: [
    "Fizemos uma pequena cobrança no seu cartão como HELP_KUSHKI VERIFICACIÓN, que será revertida imediatamente. A cobrança efetuada tem três (3) digitos, os quais deverá colocar a seguir:",
  ],
  verification: ["Verificação"],
  confirmCode: ["confirme o código"],
  securityData: ["Nos preocupamos com a segurança de seus dados."],
  securityDataMessage: [
    "Enviamos um código de verificação para o seu e-mail para confirmar a compra.",
  ],
  securityDataMessageDelete: [
    "Enviamos um código de verificação para o seu e-mail. Insira os dígitos para excluir os dados salvos.",
  ],
  agree: ["Aceite os "],
  terms: ["Termos e Condições"],
  license: ["TERMOS E CONDIÇÕES DA LICENÇA DE USO DE SOFTWARE KUSHKI"],
  licenseMessage: [
    "Este documento contém os termos e condições da Licença de Uso de Software KUSHKI S.A. (doravante “KUSHKI”), o qual, ao ser aceito pela empresa solicitante (doravante a LICENCIANTE), constitui um contrato comercial entre as Partes, neste sentido, sugerimos que você leia atentamente todo o conteúdo deste documento.",
  ],
  clause: ["CLÁUSULA PRIMEIRA.- ANTECEDENTES"],
  clauseFirstMessage: [
    "A KUSHKI é uma empresa legalmente constituída de acordo com as leis da República do Equador, cuja linha de negócios é a prestação de atividades auxiliares de serviços financeiros, por meio da prestação de serviços completos de plataforma como solução tecnológica, que permite, por meio de acordos ou alianças, prestar serviços a favor de uma determinada rede de estabelecimentos afiliados, um licenciamento de software.",
  ],
  clauseSecondMessage: [
    "KUSHKI é o proprietário dos direitos autorais sobre o programa de computador ou software denominado Kushki Pagos (doravante denominado “Software”) Pela resolução nº SB-DTL-2018-450, de 4 de maio de 2018, da Diretoria de Procedimentos Legais da Superintendência de Bancos, classificou a KUSHKI como empresa de serviços auxiliares do setor financeiro público e privado, na área transacional e de pagamentos. Por meio da resolução nº BCE-DNRO-2018-198, de 14 de agosto de 2018, o Banco Central do Equador qualificou a KUSHKI para operar um sistema auxiliar de pagamento. O LICENCIANTE manifestou o seu interesse em adquirir a licença de uso do Software KUSHKI para seu uso não exclusivo e intransferível, a fim de prestar um serviço que facilite o processamento de pagamentos online dos clientes do LICENCIANTE, por cartão de crédito.",
  ],
  headerGeneral: {
    titleHeader: ["Excelente"],
    subTitleHeader: ["Sua compra foi bem sucedida"],
  },
  headerStp: {
    titleHeader: ["Compra aguardando pagamento"],
    subTitleHeader: [
      "Insira as seguintes informações ao fazer a transferência em seu banco online",
    ],
  },
  headerCash: {
    titleHeader: ["Compra aguardando pagamento"],
    subTitleHeader: [
      "Vá até a agência do seu banco mais próximo e efetue o pagamento com as informações que fornecemos a seguir.",
    ],
  },
  headerPreAuth: {
    titleHeader: ["Excelente"],
    subTitleHeader: [
      "Sua transação foi bem-sucedida, você receberá uma cobrança temporária em seu cartão",
    ],
  },
  purchaseSummary: ["Resumo da compra"],
  purchaseSummaryTable: {
    mount: ["RESULTAR"],
    state: ["DOENÇA"],
    product: ["PRODUTOS"],
    ticketNumber: ["NÚMERO DO BILHETE"],
    businessName: ["NOME DA EMPRESA"],
    date: ["DATA"],
    name: ["NOME"],
    identification: ["EU IA"],
    mail: ["MAIL"],
    bank: ["BANCO"],
    cus: ["CUS"],
    clabeAccount: ["CONTA"],
    orderNumber: ["NÚMERO DO PEDIDO"],
  },
  changeLanguage: ["Mudar idioma"],
  anotherCard: ["Tente com outro cartão"],
  dataVerification: ["A validação falhou."],
  dataVerificationFailed: ["Falha na verificação de dados."],
  dataVerificationFailedMessage: [
    "Não foi possível verificar suas informações.",
  ],
  dataVerificationFMessage: ["O pagamento foi cancelado."],
  otpMessage: [
    "Reveja a notificação enviada pelo seu banco e introduza os dígitos da cobrança ou débito efectuado no seu cartão. Por exemplo, se a cobrança ou débito for de ",
  ],
  otpSecondMessage: [", deve introduzir esses algarismos"],
  byExample: ["Por exemplo"],
  invalidCode: ["Código inválido"],
  tryAgain: ["Por favor, tente novamente."],
  form: {
    postalCode: ["Código Postal"],
    country: ["País"],
    countryCode: ["Código do País"],
    names: ["Nomes"],
    surnames: ["sobrenomes"],
    documentType: ["Tipo de documento"],
    documentNumber: ["Número do documento"],
    state: ["Estado / Província / Região"],
    city: ["Cidade"],
    address: ["Endereço principal"],
    secondaryAddress: ["Endereço secundário (opcional)"],
    celphone: ["Telefone / celular"],
    name: ["Nombre"],
    cardNumber: ["Número do cartão"],
    mmyy: ["MM/AA"],
    identificationNumber: ["Número de identificação"],
    email: ["Email"],
    numberCardInvalid: ["* Número do cartão inválido"],
    fieldRequired: ["* Este campo é obrigatório"],
    securityCode: ["código de segurança"],
    emailInvalid: ["* Email inválido"],
    codeInvalid: ["Código inválido"],
    region: ["Região"],
    province: ["Província"],
    department: ["Departamento"],
    municipality: ["Município"],
    cvcInvalid: ["* CVC inválido"],
    expirationDateIncorrect: [
      "* Data de expiração não pode ser inferior à data atual",
    ],
    invalidDate: ["* Data Invalida"],
  },
  personType: ["Tipo de pessoa"],
  legalPerson: ["Natural"],
  naturalPerson: ["Legal"],
  payCash: ["Pagar com dinheiro"],
  payTransfer: ["Pagar com transferência"],
  payCard: ["Pagar com cartão"],
  subtotal: ["Subtotal"],
  total: ["Total"],
  taxes: ["Impostos"],
  commission: ["Comissão"],
  backButton: ["De volta ao comércio"],
  generatingConfirmation: ["Gerando confirmação"],
  emailRedirectForOTP: {
    title: ["Não te preocupes"],
    description: ["Tomamos as medidas necessárias para proteger seus dados."],
    message: [
      "Não será possível realizar operações de Kushki sem a sua confirmação.",
    ],
  },
  returnButton: ["Para retornar"],
  enterInformation: [
    "Insira as seguintes informações para prosseguir com o pagamento",
  ],
  declineTransaction: {
    declineTitle: ["Transação recusada"],
    cashTitle: ["Algo deu errado"],
    errorMessageSecurity: [
      "Por favor, certifique-se de que os dados inseridos estejam corretos e tente novamente.",
    ],
    errorMessageTimeOut: [
      "A transação foi recusada. Por favor, tente novamente",
    ],
    errorMessageCash: [
      "Nenhuma cobrança foi gerada em seu cartão de débito ou crédito",
    ],
    errorMessageIncompleteTrx: [
      "A transação não pôde ser concluída. Tente novamente.",
    ],
    errorMessageWebPay: ["O upload não pôde ser concluída."],
    errorThreeDSecureTitle: ["Erro de validação"],
    errorThreeDSecureMessage: [
      "A transação não pôde ser concluída devido a um erro de validação. Tente novamente.",
    ],
  },
  deferred: {
    checkLabel: ["Pagamento em parcelas"],
    typeDeferred: ["Tipo"],
    months: ["Cotas"],
    monthsOfGrace: ["Meses de Graça"],
  },
  payInstallmentsDisclaimer: {
    ecuadorMessage: [
      "As parcelas são processadas pelo banco, e os juros são controlados pelo banco emissor de cada cartão.",
    ],
    colombiaMessage: [
      "As parcelas são processadas pelo banco, e os juros são controlados pelo banco emissor de cada cartão.",
    ],
    peruMessage: [
      "As parcelas são processadas pelo banco, e os juros são controlados pelo banco emissor de cada cartão.",
    ],
    chileMessage: [
      "As parcelas são processadas pelo banco, e os juros são controlados pelo banco emissor de cada cartão.",
    ],
    mexicoMessage: [
      "As parcelas são processadas pelo banco, e os juros são controlados pelo banco emissor de cada cartão.",
    ],
  },
  modalLoading: {
    titlePrincipal: [
      "Aguarde enquanto redirecionamos você para a página de pagamento.",
    ],
    titleSecondary: ["Não feche esta janela."],
    subTitle: ["Por favor, espere..."],
  },
  redirectTimerMessage: {
    start: ["Você será redirecionado automaticamente em "],
    end: [" segundos."],
  },
};
