/**
 * CountryEnum
 */
export enum CountryEnum {
  ECU = "Ecuador",
  COL = "Colombia",
  PER = "Peru",
  CHL = "Chile",
  MXN = "Mexico",
  BRA = "Brazil",
  CRI = "Costa Rica",
}
