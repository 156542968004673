import { FieldMaskTypeEnum } from "../infrastructure/FieldMaskTypeEnum";

const MaskDefault: Record<FieldMaskTypeEnum, RegExp[] | string> = {
  [FieldMaskTypeEnum.TextFieldForm]: [
    /[0-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  [FieldMaskTypeEnum.InputMask]: "99999999999999999999",
};

const MaskAlphanumeric: Record<FieldMaskTypeEnum, string[] | string> = {
  [FieldMaskTypeEnum.InputMask]: "********************",
  [FieldMaskTypeEnum.TextFieldForm]: Array(20).fill(/[a-zA-Z0-9]/),
};

export const maskBuildAlphaNumeric = (
  condition: boolean,
  inputFieldType: FieldMaskTypeEnum
) => {
  if (condition) return MaskAlphanumeric[inputFieldType];
  return MaskDefault[inputFieldType];
};
