import ReactGA, { EventArgs } from "react-ga";
import { environment } from "../../environments/environment";

const gaId: string = "1234"; //TODO change to environment.gaID; //  Google Analytics ID

export const initializeGA = (): void => {
  ReactGA.initialize(gaId);
};

export const setGAEvent = (
  category: string,
  action: string,
  label: string
): void => {
  let options: EventArgs = {
    category,
    action,
    label,
  };
  ReactGA.event(options);
};
