import { ActionTypes } from "./actionTypes";
import { IWebcheckoutAction } from "./actionCreators";
import type { BinInfoResponse } from "@kushki/js/lib/types/bin_info_response";
import type { IDeferredResponse } from "@kushki/js/lib/types/remote/deferred_response";
import {
  IAdditionalInfo,
  Webcheckout,
  ExpressCheckout,
} from "../../types/webcheckout";
import { BankList } from "../../types/bank_list";
import { CheckOutResume } from "../../types/checkout_resume";
import type { MerchantSettingsResponse } from "@kushki/js/lib/types/merchant_settings_response";
import { Customization } from "../../types/merchant_fetch";
import { PaymentDataResponse } from "../../types/payment_data_response";
import { SiftScienceMerchantResponse } from "../../types/siftscience_merchant_response";
import { ICommission } from "../components/PurchaseDetail/PurchaseDetail.interfaces";
import { GetBrandsLogosByMerchantResponse } from "../../types/get_brands_logos_by_merchant_response";

export interface ISelectItem {
  value: string;
  text: string;
}
export interface IDeferredOptionsFetch {
  [k: string]: {
    months: ISelectItem[];
    monthsOfGrace: ISelectItem[];
  };
}
export interface IWebcheckoutState {
  brandsList?: GetBrandsLogosByMerchantResponse[];
  brands?: BinInfoResponse;
  optionsFetched?: IDeferredResponse[];
  banks?: BankList;
  webcheckout?: Webcheckout | ExpressCheckout;
  commission?: ICommission;
  timer?: { endTime: number };
  loading?: boolean;
  initialLoading?: boolean;
  savedPaymentMethods?: PaymentDataResponse[];
  settings?: MerchantSettingsResponse;
  receipt?: { url: string };
  activateTimer?: boolean;
  link?: { link: string };
  firebaseInfo?: CheckOutResume;
  merchantCustomizationInfo?: Customization;
  showSaveEmailModal?: boolean;
  webcheckoutEmail?: string;
  aditional_info?: IAdditionalInfo[];
  merchantId?: string;
  userBlocked?: boolean;
  merchantSiftScience?: SiftScienceMerchantResponse;
  hideTimer?: boolean;
  modalLoading?: boolean;
}

export const INITIAL_STATE: IWebcheckoutState = {};

export const useReducer = (
  state: IWebcheckoutState = INITIAL_STATE,
  action: IWebcheckoutAction
): IWebcheckoutState => {
  switch (action.type) {
    case ActionTypes.SET_BRANDS_LIST:
      return {
        ...state,
        brandsList: action.brandsList,
      };
    case ActionTypes.SET_BRANDS:
      return {
        ...state,
        brands: action.brands,
      };
    case ActionTypes.SET_DEFERRED_OPTIONS:
      return {
        ...state,
        optionsFetched: action.optionsFetched,
      };
    case ActionTypes.SET_WEBCHECKOUT:
      return {
        ...state,
        webcheckout: action.webcheckout,
      };
    case ActionTypes.SET_WEBCHECKOUT_EMAIL:
      return {
        ...state,
        webcheckoutEmail: action.email!,
      };
    case ActionTypes.SET_COMMISSION:
      return {
        ...state,
        commission: action.commission,
      };
    case ActionTypes.SET_TIMER:
      return {
        ...state,
        timer: action.timer,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case ActionTypes.SET_INITIAL_LOADING:
      return {
        ...state,
        initialLoading: action.initialLoading,
      };
    case ActionTypes.SET_BANKS:
      return {
        ...state,
        banks: action.banks,
      };
    case ActionTypes.SET_SETTINGS:
      return {
        ...state,
        settings: action.settings,
      };
    case ActionTypes.SET_RECEIPT:
      return {
        ...state,
        receipt: action.receipt,
      };
    case ActionTypes.SET_ACTIVATE_TIMER:
      return {
        ...state,
        activateTimer: action.activateTimer,
      };
    case ActionTypes.GET_ERROR_404:
      return {
        ...state,
        link: action.link,
      };
    case ActionTypes.SET_SAVED_PAYMENT_METHODS:
      return {
        ...state,
        savedPaymentMethods: action.savedPaymentMethods,
      };
    case ActionTypes.SET_INFO_FIREBASE:
      return {
        ...state,
        firebaseInfo: action.firebaseInfo,
      };
    case ActionTypes.GET_MERCHANT_CUSTOMIZATION_INFO:
      return {
        ...state,
        merchantCustomizationInfo: action.merchantCustomizationInfo,
      };
    case ActionTypes.SHOW_SAVE_EMAIL_MODAL:
      return {
        ...state,
        //TODO change to true when kpay is enabled
        showSaveEmailModal: false,
      };
    case ActionTypes.HIDE_SAVE_EMAIL_MODAL:
      return {
        ...state,
        showSaveEmailModal: false,
      };
    case ActionTypes.SET_MERCHANT_ID:
      return {
        ...state,
        merchantId: action.merchantId,
      };
    case ActionTypes.SET_USER_BLOCKED:
      return {
        ...state,
        userBlocked: action.userBlocked,
      };
    case ActionTypes.GET_MERCHANT_SIFTSCIENCE:
      return {
        ...state,
        merchantSiftScience: action.merchantSiftScience,
      };
    case ActionTypes.SET_HIDE_TIMER:
      return {
        ...state,
        hideTimer: action.hideTimer,
      };
    case ActionTypes.SET_MODAL_LOADING:
      return {
        ...state,
        modalLoading: action.modalLoading,
      };
    default:
      return state;
  }
};
