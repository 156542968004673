import { IWebcheckoutState } from "./reducer";
import { ActionTypes } from "./actionTypes";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import axios, { AxiosError, AxiosResponse } from "axios";
import type { ErrorResponse } from "@kushki/js/lib/types/error_response";
import type { BinInfoResponse } from "@kushki/js/lib/types/bin_info_response";
import { get } from "lodash";
import type { IDeferredResponse } from "@kushki/js/lib/types/remote/deferred_response";
import { environment } from "../environments/environment";
import type { TokenResponse } from "@kushki/js/lib/types/remote/token_response";
import type { TokenRequest } from "@kushki/js/lib/types/token_request";
import type { SecureOtpRequest } from "@kushki/js/lib/types/secure_otp_request";
import type { SecureOtpResponse } from "@kushki/js/lib/types/secure_otp_response";
import { Webcheckout, ExpressCheckout } from "../../types/webcheckout";
import { ChargesRequest } from "../../types/charges_request";
import { BankList } from "../../types/bank_list";
import type { TransferTokenRequest } from "@kushki/js/lib/types/remote/transfer_token_request";
import { databaseRef } from "../shared/firebase";
import { CheckOutResume } from "../../types/checkout_resume";
import type { MerchantSettingsResponse } from "@kushki/js/lib/types/merchant_settings_response";
import type { CashTokenRequest } from "@kushki/js/lib/types/cash_token_request";
import { Customization } from "../../types/merchant_fetch";
import { PaymentDataResponse } from "../../types/payment_data_response";
import type { TokenKPayRequest } from "@kushki/js/lib/types/token_kpay_request";
import type { Validate3DsResponse } from "@kushki/js/lib/types/validate_3ds_response";
import { SiftScienceMerchantResponse } from "../../types/siftscience_merchant_response";
import type { TransferSubscriptionTokenRequest } from "@kushki/js/lib/types/transfer_subscription_token_request";
import type { Validate3DSRequest } from "@kushki/js/lib/types/validate_3ds_request";
import { ICommission } from "../components/PurchaseDetail/PurchaseDetail.interfaces";
import type { CardAsyncTokenRequest } from "@kushki/js/lib/types/card_async_token_request";
import type { CardAsyncTokenResponse } from "@kushki/js/lib/types/card_async_token_response";
import { KInfo } from "../KushkiInfo";
import { GetBrandsLogosByMerchantResponse } from "../../types/get_brands_logos_by_merchant_response";
import visa from "../assets/logos/visa.svg";
import mastercard from "../assets/logos/Mastercard.svg";
import { CardBrandsEnum } from "../shared/infrastructure/CardBrandsEnum";
import { CardBrandingRequest } from "@kushki/js/lib/types/card_branding_request";
import { useMediaQuery } from "@material-ui/core";

export type IWebcheckoutAction = {
  type: string;
  email?: string;
  endTime?: { endTime: number };
} & IWebcheckoutState;

export const setBrands = (
  payload: GetBrandsLogosByMerchantResponse[]
): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_BRANDS_LIST,
    brandsList: payload,
  };
};

export const setCardBrand = (payload: BinInfoResponse): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_BRANDS,
    brands: payload,
  };
};

export const setMerchantId = (payload: string): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_MERCHANT_ID,
    merchantId: payload,
  };
};

export const setWebcheckout = (
  payload: Webcheckout | ExpressCheckout
): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_WEBCHECKOUT,
    webcheckout: payload,
  };
};

export const getError404 = (payload: { link: string }): IWebcheckoutAction => {
  return {
    type: ActionTypes.GET_ERROR_404,
    link: payload,
  };
};

export const setCommission = (payload: ICommission): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_COMMISSION,
    commission: payload,
  };
};

export const setTimer = (payload?: { endTime: number }): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_TIMER,
    timer: payload,
  };
};

export const setLoading = (payload: boolean): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_LOADING,
    loading: payload,
  };
};
export const setInitialLoading = (payload: boolean): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_INITIAL_LOADING,
    initialLoading: payload,
  };
};
export const setSavedPaymentMethods = (
  payload: PaymentDataResponse[]
): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_SAVED_PAYMENT_METHODS,
    savedPaymentMethods: payload,
  };
};

export const setBanks = (payload: BankList): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_BANKS,
    banks: payload,
  };
};

export const setReceipt = (payload: { url: string }): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_RECEIPT,
    receipt: payload,
  };
};

export const setActivateTimer = (payload: boolean): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_ACTIVATE_TIMER,
    activateTimer: payload,
  };
};

export const getMerchantCustomizationInfo = (
  payload: Customization
): IWebcheckoutAction => {
  return {
    type: ActionTypes.GET_MERCHANT_CUSTOMIZATION_INFO,
    merchantCustomizationInfo: payload,
  };
};

export const setHideTimer = (payload: boolean): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_HIDE_TIMER,
    hideTimer: payload,
  };
};

export const setMerchantSiftScience = (
  payload: SiftScienceMerchantResponse
): IWebcheckoutAction => {
  return {
    type: ActionTypes.GET_MERCHANT_SIFTSCIENCE,
    merchantSiftScience: payload,
  };
};

const setDeferredOptionsAction: (
  payload: IDeferredResponse[]
) => IWebcheckoutAction = (
  payload: IDeferredResponse[]
): IWebcheckoutAction => ({
  type: ActionTypes.SET_DEFERRED_OPTIONS,
  optionsFetched: payload,
});

export const setSettings = (
  payload: MerchantSettingsResponse
): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_SETTINGS,
    settings: payload,
  };
};

export const setInfoFirebase = (
  payload: CheckOutResume
): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_INFO_FIREBASE,
    firebaseInfo: payload,
  };
};

export const showSaveEmailModal = (): IWebcheckoutAction => {
  return {
    type: ActionTypes.SHOW_SAVE_EMAIL_MODAL,
  };
};

export const hideSaveEmailModal = (): IWebcheckoutAction => {
  return {
    type: ActionTypes.HIDE_SAVE_EMAIL_MODAL,
  };
};

export const setWebcheckoutEmail = (payload: string): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_WEBCHECKOUT_EMAIL,
    email: payload,
  };
};

export const setUserBlocked = (): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_USER_BLOCKED,
    userBlocked: true,
  };
};

export const setModalLoading = (payload: boolean): IWebcheckoutAction => {
  return {
    type: ActionTypes.SET_MODAL_LOADING,
    modalLoading: payload,
  };
};

export const getWebcheckout = (
  webcheckoutId: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    const path: string = "smartlink/v1/webcheckout";
    dispatch(setInitialLoading(true));

    axios
      .get(`${environment.kushkiUrl}/${path}/${webcheckoutId}`)
      .then((response: AxiosResponse<Webcheckout>) => {
        dispatch(setWebcheckout(response.data));
        dispatch(setMerchantId(response.data.publicMerchantId));
        const paymentMethods: string[] | string =
          response.data.paymentConfig.paymentMethod;
        dispatch(getMerchantSettings(response.data.publicMerchantId));
        const fetchBanks = () => {
          dispatch(getBankList(response.data.publicMerchantId));
          dispatch(getMerchantCustomization(response.data.publicMerchantId));
        };

        dispatch(getMerchantSiftScience(response.data.publicMerchantId));
        switch (typeof paymentMethods) {
          case "string":
            if (paymentMethods === "transfer") fetchBanks();
            break;
          default:
            if (paymentMethods.find((value) => value === "transfer"))
              fetchBanks();
            break;
        }
      })
      .catch(() => {
        dispatch(getError404({ link: "error-404" }));
      })
      .finally(() => {
        dispatch(setInitialLoading(false));
      });
  };
};

export const getListBrandsMerchant = (
  publicMerchantId: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    const path: string = "card/v1/merchant/brands-logos";
    axios
      .get(`${environment.kushkiUrl}/${path}`, {
        headers: {
          ["Public-Merchant-Id"]: publicMerchantId,
        },
      })
      .then((response: AxiosResponse<GetBrandsLogosByMerchantResponse[]>) => {
        if (!response.hasOwnProperty("message")) {
          let listBrand: GetBrandsLogosByMerchantResponse[] = [];
          const card_list: GetBrandsLogosByMerchantResponse[] = response.data;
          if (card_list.length === 0) {
            listBrand.push({
              brand: "visa",
              url: visa,
            });
            listBrand.push({
              brand: "masterCard",
              url: mastercard,
            });
          } else listBrand = listBrand.concat(card_list);
          dispatch(setBrands(listBrand));
        }
      })
      .catch(() => {
        dispatch(getError404({ link: "error-404" }));
      });
  };
};

export const getCardBrand = (
  publicMerchantId: string,
  binNumber: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      const kushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
      });
      kushki.requestBinInfo(
        {
          bin: binNumber,
        },
        (value: BinInfoResponse | ErrorResponse) => {
          if (value.hasOwnProperty("brand")) {
            const brand_card: BinInfoResponse = value as BinInfoResponse;

            dispatch(setCardBrand({ ...brand_card }));
          } else {
            let brandLogo: string = "";

            const brands: { brand: string; pattern: RegExp }[] = [
              { brand: "visa", pattern: /^4/ },
              {
                brand: "mastercard",
                pattern: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)/,
              },
              { brand: "amex", pattern: /^3[47]/ },
              { brand: "dinersclub", pattern: /^3(?:0[0-5]|[68][0-9])/ },
              { brand: "discover", pattern: /^6(?:011|5[0-9]{2})/ },
            ];

            const new_brand:
              | { brand: string; pattern: RegExp }
              | undefined = brands.find(
              (elem: { brand: string; pattern: RegExp }) =>
                elem.pattern.test(binNumber)
            );

            brandLogo = get(new_brand, "brand", "");

            dispatch(
              setCardBrand({
                brand: brandLogo,
                cardType: get(value, "cardType"),
                bank: get(value, "bank"),
              })
            );
          }
        }
      );
    });
  };
};

export const getDeferredOptions = (
  publicMerchantId: string,
  binNumber: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      const kushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
      });
      kushki.requestDeferred(
        {
          bin: binNumber,
        },
        (response: IDeferredResponse[] | ErrorResponse) => {
          if (!response.hasOwnProperty("message")) {
            dispatch(setDeferredOptionsAction(response as IDeferredResponse[]));
          }
        }
      );
    });
  };
};
export const getCommission = (
  publicMerchantId: string,
  totalAmount: number,
  currency: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    dispatch(setInitialLoading(true));
    const path: string = "commission/v1/configuration";
    axios
      .post(
        `${environment.kushkiUrl}/${path}`,
        {
          currency,
          totalAmount,
        },
        {
          headers: {
            ["Public-Merchant-Id"]: publicMerchantId,
          },
        }
      )
      .then((response: AxiosResponse) => {
        dispatch(setCommission(response.data));
      })
      .catch(() => {})
      .finally(() => dispatch(setInitialLoading(false)));
  };
};

export const getToken = (
  publicMerchantId: string,
  tokenRequest: TokenRequest | TokenKPayRequest,
  handleResponse: (token: TokenResponse | ErrorResponse) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      dispatch(setLoading(true));
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
        kushkiInfo: KInfo.kushkiInfo,
      });
      libKushki.requestToken(tokenRequest, (token) => {
        dispatch(setLoading(false));
        handleResponse(token);
      });
    });
  };
};

export const getCardAsyncToken = (
  publicMerchantId: string,
  tokenRequest: CardAsyncTokenRequest,
  handleResponse: (token: CardAsyncTokenResponse | ErrorResponse) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      dispatch(setModalLoading(true));
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
        kushkiInfo: KInfo.kushkiInfo,
      });

      libKushki.requestCardAsyncToken(tokenRequest, (token) => {
        dispatch(setModalLoading(false));
        handleResponse(token);
      });
    });
  };
};

export const validate3Ds = (
  publicMerchantId: string,
  tokenResponse: TokenResponse,
  handleValidate3Ds: (
    token: ErrorResponse | Validate3DsResponse,
    data: TokenResponse
  ) => void,
  onError: () => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
      });
      const validateRequest: Validate3DSRequest = {
        secureId: get(tokenResponse, "secureId", ""),
        security: {
          ...tokenResponse.security,
          authRequired: get(tokenResponse, "security.authRequired", false),
        },
      };

      dispatch(setLoading(true));
      libKushki.requestValidate3DS(
        validateRequest,
        (response: ErrorResponse | Validate3DsResponse) => {
          if (get(response, "isValid", false)) {
            handleValidate3Ds(response, tokenResponse);
          } else {
            onError();
            dispatch(setLoading(false));
          }
        }
      );
    });
  };
};

export const getCashToken = (
  publicMerchantId: string,
  tokenRequest: CashTokenRequest,
  handleResponse: (token: TokenResponse | ErrorResponse) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      dispatch(setLoading(true));
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
        kushkiInfo: KInfo.kushkiInfo,
      });
      libKushki.requestCashToken(tokenRequest, (token) => {
        dispatch(setLoading(false));
        handleResponse(token);
      });
    });
  };
};

export const getTransferToken = (
  publicMerchantId: string,
  tokenTransferRequest: TransferTokenRequest,
  handleTransferToken: (token: TokenResponse | ErrorResponse) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      dispatch(setLoading(true));
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
        kushkiInfo: KInfo.kushkiInfo,
      });
      libKushki.requestTransferToken(tokenTransferRequest, (token) => {
        if ("code" in tokenTransferRequest || "message" in token) {
          dispatch(setLoading(false));
        }
        handleTransferToken(token);
      });
    });
  };
};

export const secureServiceValidation = (
  publicMerchantId: string,
  secureServiceRequest: SecureOtpRequest,
  handleResponse: (response: SecureOtpResponse | ErrorResponse) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      dispatch(setLoading(true));
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
      });
      libKushki.requestSecureServiceValidation(
        secureServiceRequest,
        (response: SecureOtpResponse | ErrorResponse) => {
          dispatch(setLoading(false));
          handleResponse(response);
        }
      );
    });
  };
};

export const initCardBrandingAnimation = (
  brand: CardBrandsEnum,
  publicMerchantId: string,
  data: object,
  onSuccess: (data: object) => void,
  isMobile: boolean
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
      });

      const getAnimationParams = (): CardBrandingRequest => {
        if (brand === CardBrandsEnum.VISA)
          return {
            brand: CardBrandsEnum.VISA,
            checkmark: "checkmark",
            constrained: !isMobile,
            color: isMobile ? "blur" : "transparent",
            sound: true,
          };
        else
          return {
            brand: CardBrandsEnum.MASTERCARD,
            type: "default",
            clearBackground: true,
          };
      };

      try {
        libKushki.initCardBrandingAnimation(() => {
          onSuccess(data);
        }, getAnimationParams());
      } catch (_e) {
        onSuccess(data);
      }
    });
  };
};

export const charge = (
  smartLinkId: string,
  chargeRequest: ChargesRequest,
  onSuccess: (data: any) => void,
  onError: (e?: Error) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    dispatch(setLoading(true));
    axios
      .post(
        `${environment.kushkiUrl}/smartlink/charges/${smartLinkId}`,
        chargeRequest
      )
      .then((response: AxiosResponse<any>) => {
        onSuccess(response.data);
        dispatch(setLoading(!!get(response.data, "redirectUrl")));
      })
      .catch((e) => {
        const dataError = get(e, "response.data");
        dispatch(setLoading(false));
        return onError(dataError);
      });
  };
};

export const chargeAsync = (
  smartLinkId: string,
  chargeRequest: ChargesRequest,
  onSuccess: (data: any) => void,
  onError: (e?: Error) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    dispatch(setModalLoading(true));
    axios
      .post(
        `${environment.kushkiUrl}/smartlink/charges/${smartLinkId}`,
        chargeRequest
      )
      .then((response: AxiosResponse<any>) => {
        dispatch(setModalLoading(!!response.data.redirectUrl));
        onSuccess(response.data);
      })
      .catch((e) => {
        const dataError = get(e, "response.data");
        dispatch(setModalLoading(false));
        return onError(dataError);
      });
  };
};

export const getBankList = (
  publicMerchantId: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    axios
      .get(`${environment.kushkiUrl}/transfer/v1/bankList`, {
        headers: {
          ["Public-Merchant-Id"]: publicMerchantId,
        },
      })
      .then((response: AxiosResponse<BankList>) => {
        dispatch(setBanks(response.data));
      })
      .catch(() => {
        dispatch(
          setBanks([
            { code: "0", name: "A continuación seleccione su banco" },
            { code: "0001", name: "Kushki bank Colombia" },
            { code: "0002", name: "Kushki bank Ecuador" },
            { code: "0003", name: "Kushki bank Chile" },
            { code: "0004", name: "Kushki bank Peru" },
            { code: "0005", name: "Kushki bank México" },
            { code: "0006", name: "Kushki bank Brasil" },
            { code: "0007", name: "Kushki bank USA" },
          ])
        );
      });
  };
};

export const getMerchantSettings = (
  publicMerchantId: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    axios
      .get(`${environment.kushkiUrl}/merchant/v1/merchant/settings`, {
        headers: {
          ["Public-Merchant-Id"]: publicMerchantId,
        },
      })
      .then((response: AxiosResponse<MerchantSettingsResponse>) => {
        dispatch(setSettings(response.data));
      })
      .catch(() => {});
  };
};

export const getReceiptUrl = (
  merchantId: string,
  transactionReference: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  let receipt_interval: NodeJS.Timeout;

  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    axios
      .post(`${environment.kushkiUrl}/smartlink/v1/webcheckout/receipt`, {
        merchantId,
        transactionReference,
      })
      .then((response: AxiosResponse<{ url: string }>) => {
        receipt_interval = setInterval(() => {
          dispatch(setReceipt(response.data));
          dispatch(setLoading(false));
          clearInterval(receipt_interval);
        }, 6000);
      })
      .catch((e: AxiosError) => {
        if (get(e, "response.data.code") === "WCH006") {
          dispatch(setLoading(true));
          dispatch(getReceiptUrl(merchantId, transactionReference));
        } else {
          dispatch(setLoading(false));
          clearInterval(receipt_interval);
        }
      });
  };
};

export const getInfoFirebase = (
  token: string,
  resumeType: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    databaseRef()
      .child(
        `${environment.envName}/webcheckout/notification/${resumeType}-${token}`
      )
      .on("value", (snapshot) => {
        const transactionInfo: object = snapshot.val();

        if (transactionInfo !== undefined && transactionInfo !== null) {
          dispatch(setInfoFirebase(snapshot.val()));
        }
      });
  };
};

export const getMerchantCustomization = (
  publicMerchantId: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    axios
      .get(`${environment.kushkiUrl}/merchant/v1/merchant/customization`, {
        headers: {
          ["Public-Merchant-Id"]: publicMerchantId,
        },
      })
      .then((response: AxiosResponse<Customization>) => {
        localStorage.setItem(
          "contactEmail",
          get(response, "data.contact.email", "")
        );
        localStorage.setItem(
          "contactPhoneNumber",
          get(response, "data.contact.phoneNumber", "")
        );
        dispatch(getMerchantCustomizationInfo(response.data));
      })
      .catch(() => {});
  };
};

export const getMerchantSiftScience = (
  publicMerchantId: string
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    axios
      .get(`${environment.kushkiUrl}/merchant/v1/merchant/siftscience`, {
        headers: {
          ["Public-Merchant-Id"]: publicMerchantId,
        },
      })
      .then((response: AxiosResponse<SiftScienceMerchantResponse>) => {
        dispatch(setMerchantSiftScience(response.data));
      })
      .catch(() => {});
  };
};

export const requestTransferSubscriptionToken = (
  publicMerchantId: string,
  request: TransferSubscriptionTokenRequest,
  handleResponse: (token: TokenResponse | ErrorResponse) => void
): ThunkAction<void, IWebcheckoutState, undefined, IWebcheckoutAction> => {
  return (
    dispatch: ThunkDispatch<IWebcheckoutState, any, IWebcheckoutAction>
  ): void => {
    import("@kushki/js/lib/Kushki").then(({ Kushki }) => {
      dispatch(setLoading(true));
      const libKushki = new Kushki({
        merchantId: publicMerchantId,
        inTestEnvironment: environment.envName !== "primary",
        kushkiInfo: KInfo.kushkiInfo,
      });
      libKushki.requestTransferSubscriptionToken(request, handleResponse);
    });
  };
};
