export const CurrencyEnum: Record<string, string> = {
  Ecuador: "USD",
  Colombia: "COP",
  Chile: "CLP",
  Mexico: "MXN",
  Peru: "PEN",
  Brazil: "BRL",
  CostaRica: "CRC",
  ELSalvador: "USD",
  Guatemala: "GTQ",
  Honduras: "HNL",
  Nicaragua: "NIO",
  Panama: "PAB",
};
