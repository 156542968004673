export const IdentificationNumberEnum: Record<
  string,
  { value: string; label: string }[]
> = {
  Ecuador: [
    { value: "CI", label: "CI" },
    { value: "PAS", label: "PAS" },
    { value: "RUC", label: "RUC" },
  ],
  Peru: [
    { value: "DNI", label: "DNI" },
    { value: "CE", label: "CE" },
    { value: "PAS", label: "PAS" },
  ],
  Colombia: [
    { value: "CC", label: "CC" },
    { value: "NIT", label: "NIT" },
    { value: "CE", label: "CE" },
    { value: "TI", label: "TI" },
    { value: "PAS", label: "PAS" },
  ],
  Mexico: [
    { value: "PP", label: "PP" },
    { value: "CURP", label: "CURP" },
    { value: "RFC", label: "RFC" },
  ],
  Brazil: [
    { value: "CPF", label: "CPF" },
    { value: "CNPJ", label: "CNPJ" },
    { value: "RG", label: "RG" },
    { value: "DNI", label: "DNI" },
    { value: "CI", label: "CI" },
    { value: "CP", label: "CP" },
    { value: "RNE", label: "RNE" },
  ],
  Chile: [
    { value: "CC", label: "CC" },
    { value: "NIT", label: "NIT" },
    { value: "RUT", label: "RUT" },
    { value: "TI", label: "TI" },
    { value: "PP", label: "PP" },
    { value: "CE", label: "CE" },
  ],
  CostaRica: [
    { value: "CI", label: "CI" },
    { value: "NITE", label: "NITE" },
  ],
  Guatemala: [
    { value: "DPI", label: "DPI" },
    { value: "NIT", label: "NIT" },
  ],
  Panama: [
    { value: "CIP", label: "CIP" },
    { value: "NIT", label: "NIT" },
  ],
  Nicaragua: [
    { value: "CI", label: "CI" },
    { value: "RUC", label: "RUC" },
  ],
  Honduras: [
    { value: "RNP", label: "RNP" },
    { value: "RTN", label: "RTN" },
  ],
  ElSalvador: [
    { value: "DUI", label: "DUI" },
    { value: "NIT", label: "NIT" },
  ],
};
