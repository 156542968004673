export class StringUtils {
  static removeBlanksAndConvertToLowercase(text: string): string {
    return text.replace(" ", "").toLowerCase();
  }

  static getContrastYIQ(hexcolor: string = "#000000"): [string, string] {
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1);
    }

    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split("")
        .map(function (hex) {
          return hex + hex;
        })
        .join("");
    }

    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    const [targetColor, lighten]: [string, number] =
      yiq >= 128 ? ["#293036", -25] : ["#FFFFFF", 25];
    const lightenColor: string = StringUtils.lightenDarkerColor(
      hexcolor,
      lighten
    );

    return [targetColor, lightenColor];
  }

  static lightenDarkerColor(color: string, amount: number): string {
    const parsedColor = color.replace("#", "");

    const redChannel: string = StringUtils.getColorChannel(
      parsedColor.substring(0, 2),
      amount
    );
    const greenChannel: string = StringUtils.getColorChannel(
      parsedColor.substring(2, 4),
      amount
    );
    const blueChannel: string = StringUtils.getColorChannel(
      parsedColor.substring(4, 6),
      amount
    );

    return `#${redChannel}${greenChannel}${blueChannel}`;
  }

  static getColorChannel(substring: string, amount: number): string {
    const channelLength: number = parseInt(substring, 16) + amount;
    const colorChannel: string = Math.max(
      Math.min(255, channelLength),
      0
    ).toString(16);

    return colorChannel.length < 2 ? `0${colorChannel}` : colorChannel;
  }
}
