/* istanbul ignore file */
import { spanish } from "../languages/Spanish";
import { english } from "../languages/English";
import { portuguese } from "../languages/Portugues";

export const availableLanguages: ILanguage[] = [
  { name: "Español", code: "es" },
  { name: "English", code: "en" },
  { name: "Português", code: "pt" },
];

export interface ILanguage {
  name: string;
  code: string;
}

export interface ITextLanguages {
  en: string[];
  es: string[];
  pt: string[];
}
export interface IFooter {
  contacts: string[];
  help: string[];
  messageFooter: string[];
  messagePay: string[];
  readMore: string[];
  changeLanguage: string[];
}
export interface IConfirMessage {
  title: string[];
  messageComplete: string[];
  message: string[];
  messageSecond: string[];
  cancel: string[];
  ok: string[];
}
export interface IPurchaseDetail {
  title: string[];
  totalToPay: string[];
  item: string[];
  information: string[];
  breakdown: string[];
  paymentDescription: string[];
}
export interface IListPaymentMethods {
  titlePayMethods: string[];
  titlePaySaved: string[];
  titleOtherPay: string[];
  titleCard: string[];
  titleDebit: string[];
  titleCash: string[];
  titleTransfer: string[];
  titleCardAndDebit: string[];
  titleCardDynamic: string[];
  expiredCardMessage: string[];
}
export interface ICard {
  titleCard: string[];
}
export interface ILoadingTCard {
  mainLine: string[];
  secondaryLine: string[];
}
export interface IForm {
  names: string[];
  surnames: string[];
  documentType: string[];
  documentNumber: string[];
  country: string[];
  region: string[];
  municipality: string[];
  department: string[];
  province: string[];
  state: string[];
  city: string[];
  postalCode: string[];
  address: string[];
  secondaryAddress: string[];
  countryCode: string[];
  celphone: string[];
  name: string[];
  cardNumber: string[];
  mmyy: string[];
  identificationNumber: string[];
  email: string[];
  numberCardInvalid: string[];
  fieldRequired: string[];
  securityCode: string[];
  emailInvalid: string[];
  codeInvalid: string[];
  cvcInvalid: string[];
  expirationDateIncorrect: string[];
  invalidDate: string[];
}
export interface IEmailRedirectForOTP {
  title: string[];
  description: string[];
  message: string[];
}

export interface IAppTranslation {
  payButton: string[];
  payButtonWithMount: string[];
  pageNotFound: string[];
  footer: IFooter;
  confirmMessage: IConfirMessage;
  languages: ITextLanguages;
  purchaseDetail: IPurchaseDetail;
  listPaymentMethods: IListPaymentMethods;
  card: ICard;
  messageTimeSession: string[];
  userName: string[];
  changeTypePay: string[];
  checkInformationSave: string[];
  termsConditions: string[];
  messagePCI: string[];
  safetyPay: string[];
  loadingTCard: ILoadingTCard;
  loadingCard: ILoadingTCard;
  sessionExpired: string[];
  backStore: string[];
  sessionExpiredTitle: string[];
  downloadConfirmation: string[];
  helpKushkiVerification: string[];
  verification: string[];
  confirmCode: string[];
  securityData: string[];
  securityDataMessage: string[];
  securityDataMessageDelete: string[];
  terms: string[];
  license: string[];
  licenseMessage: string[];
  clause: string[];
  clauseFirstMessage: string[];
  clauseSecondMessage: string[];
  agree: string[];
  headerGeneral: {
    titleHeader: string[];
    subTitleHeader: string[];
  };
  headerStp: {
    titleHeader: string[];
    subTitleHeader: string[];
  };
  headerCash: {
    titleHeader: string[];
    subTitleHeader: string[];
  };
  headerPreAuth: {
    titleHeader: string[];
    subTitleHeader: string[];
  };
  purchaseSummary: string[];
  purchaseSummaryTable: {
    mount: string[];
    state: string[];
    product: string[];
    ticketNumber: string[];
    businessName: string[];
    date: string[];
    name: string[];
    identification: string[];
    mail: string[];
    bank: string[];
    cus: string[];
    clabeAccount: string[];
    orderNumber: string[];
  };
  changeLanguage: string[];
  anotherCard: string[];
  dataVerification: string[];
  dataVerificationFailed: string[];
  dataVerificationFailedMessage: string[];
  dataVerificationFMessage: string[];
  otpMessage: string[];
  otpSecondMessage: string[];
  byExample: string[];
  invalidCode: string[];
  tryAgain: string[];
  personType: string[];
  legalPerson: string[];
  naturalPerson: string[];
  payCash: string[];
  payTransfer: string[];
  WrongPasswordless: {
    dataVerification: string[];
    securityData: string[];
    securityDataMessage: string[];
    securityRememberMessage: string[];
  };
  payCard: string[];
  subtotal: string[];
  total: string[];
  taxes: string[];
  commission: string[];
  form: IForm;
  backButton: string[];
  generatingConfirmation: string[];
  emailRedirectForOTP: IEmailRedirectForOTP;
  returnButton: string[];
  enterInformation: string[];
  declineTransaction: {
    declineTitle: string[];
    cashTitle: string[];
    errorMessageSecurity: string[];
    errorMessageTimeOut: string[];
    errorMessageCash: string[];
    errorMessageIncompleteTrx: string[];
    errorMessageWebPay: string[];
    errorThreeDSecureTitle: string[];
    errorThreeDSecureMessage: string[];
  };
  deferred: {
    checkLabel: string[];
    typeDeferred: string[];
    months: string[];
    monthsOfGrace: string[];
  };
  payInstallmentsDisclaimer: {
    ecuadorMessage: string[];
    colombiaMessage: string[];
    peruMessage: string[];
    chileMessage: string[];
    mexicoMessage: string[];
  };
  modalLoading: {
    titlePrincipal: string[];
    titleSecondary: string[];
    subTitle: string[];
  };
  redirectTimerMessage: {
    start: string[];
    end: string[];
  };
}

export const getAppTranslations: () => IAppTranslation = (): IAppTranslation =>
  <IAppTranslation>mergeLanguages(spanish, [english, portuguese]);

function mergeLanguages(
  defaultLanguage: object,
  translateOptions: object[]
): object {
  const result: object = defaultLanguage;
  translateOptions.forEach((translations: object) => {
    Object.keys(translations).forEach((key: string) => {
      // @ts-ignore
      if (Array.isArray(translations[key])) {
        // @ts-ignore
        result[key] = [...result[key].concat(translations[key])];
      } else {
        // @ts-ignore
        const subKeys: string[] = Object.keys(translations[key]);

        subKeys.forEach((subKey: string) => {
          // @ts-ignore
          result[key][subKey] = [
            // @ts-ignore
            ...result[key][subKey].concat(translations[key][subKey]),
          ];
        });
      }

      return;
    });
  });

  return result;
}
