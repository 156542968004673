export enum ProcessorsEnum {
  PSE = "Pse Processor",
  ACCENDO = "Accendo Processor",
  ETPAY = "ETPay Processor",
  SAFETY_PAY_TRANSFER = "SafetyPay Processor",
  SAFETY_PAY_CASH = "SafetyPay",
  SENCILLITO = "Sencillito",
  FACILITO = "Facilito",
  BANCO_BOGOTA = "BancoBogota",
  PAYVALIDA = "PayValida",
  PAYCASH_PROCESSOR = "PayCash Processor",
}
