export enum SiftscienceFieldsEnum {
  FIRSTNAME = "firstName",
  LASTNAME = "lastName",
  EMAIL = "email",
  ADDRESS = "address",
  SECONDARY_ADDRESS = "secondaryAddress",
  CITY = "city",
  ZIP_CODE = "zipCode",
  COUNTRY = "country",
  REGION = "region",
  PHONE_NUMBER = "phone",
}
