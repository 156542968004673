export const GA_CONSTANTS = {
  actions: {
    click: "Click",
    view: "Cargar Vista",
    select: "Selecciona",
  },
  paymentMethods: {
    category: "Paso 1 -  Selección de medios de pago",
    labels: {
      A: "Clic en Tarj. Credito o Debito",
      B: "Clic en Transferencia",
      C: "Clic en Efectivo",
    },
  },
  dataForCreditCard: {
    category: "Paso 2 -  Registrar Tarj. Credito o Debito",
    labels: {
      A1: "Clic en Nombre",
      A2: "Clic en N° de Traj.",
      A3: "Clic en MM/AA",
      A4: "Clic en CVC",
      A5: "Clic en Pagar en cuotas",
      A6: "Clic en Acepto términos y Condiciones",
      A7: "Clic en Guardar datos de Traj. para compras futuras",
      A8: "Clic en Pagar",
      A9: "Page View / Loader Card",
    },
  },
  otp: {
    category: "Paso 2 -  Registrar Tarj. Credito o Debito",
    labels: {
      A10: "Clic en Intentar con otra tarjeta",
      A11: "Clic en  Caja para ingresar cód de verificación",
      A12: "Clic en  Confirmar código",
    },
  },
  cardTransactionSummary: {
    category: "Paso 4 -  Resumen de transacción Tarj.",
    labels: {
      A13: "Page view / Trans. Resumen Card",
      A14: "Clic en descargar confirmación",
      A15: "Clic en regresar a tienda",
    },
  },
  dataForTransfer: {
    category: "Paso 2 -  Registrar datos para Transferencia",
    labels: {
      B1: "Clic en Elije tu banco",
      B2: "Selecciona el Tipo de persona - Natural",
      B3: "Selecciona el Tipo de persona - Juridica",
      B4: "Clic en Nombre",
      B5: "Clic en  Tipo de documento",
      B6: "Clic en N° de documento",
      B7: "Clic en Email",
      B8: "Clic en Acepto términos y Condiciones",
      B9: "Clic en Guardar datos, para compras futuras",
      B10: "Clic en Pagar",
    },
  },
  purchaseSummary: {
    category: "Paso 3 -  Resumen de la compra",
    labels: {
      B10: "Page view / Resumen de la compra",
      B11: "Clic en descargar confirmación",
    },
  },
  dataForCash: {
    category: "Paso 2 -  Registrar datos para Efectivo",
    labels: {
      C1: "Clic en Nombre",
      C2: "Clic en  Tipo de documento",
      C3: "Clic en N° de documento",
      C4: "Clic en Email",
      C5: "Clic en Acepto términos y Condiciones",
      C6: "Clic en Guardar datos para compras futuras",
      C7: "Clic en Pagar",
    },
  },
  purchaseSummaryPayment: {
    category: "Paso 3 -  Resumen de la compra en espera de pago",
    labels: {
      C8: "Page view / Resumen de la compra en espera de pago",
      C9: "Clic en descargar confirmación",
    },
  },
  dataValidation: {
    category: "Paso 1 -  Validación de datos",
    labels: {
      E1: "Clic en Intentar con otra tarjeta",
      E2: "Clic en Cód de seguridad",
      E3: "Clic en Confirmar código.",
    },
  },
  CVCCode: {
    category: "Paso 2 -  Ingresar cód CVC",
    labels: {
      E4: "Clic en CVC",
      E5: "Clic en Pagar en cuotas",
      E6: "Clic en Acepto los términos y condiciones",
      E7: "Clic en Pagar",
    },
  },
  successfulPurchaseSummary: {
    category: "Paso 4 -  Resumen de compra exitos",
    labels: {
      E8: "Page view / Resumen de compra exitosa",
      E9: "Clic en descargar confirmación",
    },
  },
  turnoverRate: {
    category: "Paso 1 -  Turnover Rate",
    labels: {
      Z0: "Volver al comercio",
      Z1: "Cambiar medio de pago",
    },
  },
  rateOfReturn: {
    category: "Rate of return",
    labels: {
      G1: "Regresar a la tienda",
    },
  },
  modalAlert: {
    category: "Paso 1 -  Modal Alert",
    labels: {
      0: "Volver al comercio",
      1: "Cambiar medio de pago",
    },
  },
  warning: {
    category: "Paso 1 -  Modal Alert",
    labels: {
      0: "Volver al comercio",
      1: "Cambiar medio de pago",
    },
  },
  expiredPage: {
    category: "Páginas de resultado con error",
    labels: {
      H1: "Page view - Sesión expirada",
      H2: "Algo salió mal (conexión con el banco)",
      H3: "Transacción declinada",
      H4: "Algo salió mal",
    },
  },
};
