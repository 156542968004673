import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import { IWebcheckoutState } from "../store/reducer";
import { Webcheckout, ExpressCheckout } from "../../types/webcheckout";

export interface AppState {
  isMobile: boolean;
  webcheckout: Webcheckout | undefined | ExpressCheckout;
}

export const useStateApp = (): AppState => {
  const webcheckout = useSelector(
    (state: IWebcheckoutState) => state.webcheckout
  );

  return {
    isMobile: useMediaQuery("(min-width:959px)"),
    webcheckout,
  };
};
