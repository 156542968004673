import Sencillito from "../../assets/Cash/Chile/Sencillito/Sencillito.png";
import Acuenta from "../../assets/Cash/Chile/Sencillito/Acuenta.png";
import CajaVecina from "../../assets/Cash/Chile/Sencillito/CajaVecina.png";
import ExpressLider from "../../assets/Cash/Chile/Sencillito/ExpressLider.png";
import Lider from "../../assets/Cash/Chile/Sencillito/Lider.png";
import Serviestado from "../../assets/Cash/Chile/Sencillito/Serviestado.png";
import Walmart from "../../assets/Cash/Chile/Sencillito/Walmart.png";
import BalotoPayvalida from "../../assets/Cash/Colombia/BancoBogota/BalotoPayvalida.png";
import Carulla from "../../assets/Cash/Colombia/BancoBogota/Carulla.png";
import Cerca from "../../assets/Cash/Colombia/BancoBogota/Cerca.png";
import Colsubsidio from "../../assets/Cash/Colombia/BancoBogota/Colsubsidio.png";
import ConsuerteBcoBogota from "../../assets/Cash/Colombia/BancoBogota/ConsuerteBcoBogotá.png";
import Coopenessa from "../../assets/Cash/Colombia/BancoBogota/Coopenessa.png";
import Edeq from "../../assets/Cash/Colombia/BancoBogota/Edeq.png";
import EfectyPayvalida from "../../assets/Cash/Colombia/BancoBogota/EfectyPayvalida.png";
import Exito from "../../assets/Cash/Colombia/BancoBogota/Exito.png";
import Fullcarga from "../../assets/Cash/Colombia/BancoBogota/Fullcarga.png";
import Jer from "../../assets/Cash/Colombia/BancoBogota/Jer.png";
import La14 from "../../assets/Cash/Colombia/BancoBogota/La14.png";
import Moviired from "../../assets/Cash/Colombia/BancoBogota/Moviired.png";
import Suchance from "../../assets/Cash/Colombia/BancoBogota/Suchance.png";
import SuperInter from "../../assets/Cash/Colombia/BancoBogota/SuperInter.png";
import Surtimax from "../../assets/Cash/Colombia/BancoBogota/Surtimax.png";
import Surtimayorista from "../../assets/Cash/Colombia/BancoBogota/Surtimayorista.png";
import Apostar from "../../assets/Cash/Colombia/Payvalida/Apostar.png";
import Bancolombia from "../../assets/Cash/Colombia/Payvalida/Bancolombia.png";
import Dimonex from "../../assets/Cash/Colombia/Payvalida/Dimonex.png";
import Gana from "../../assets/Cash/Colombia/Payvalida/Gana.png";
import Puntored from "../../assets/Cash/Colombia/Payvalida/Puntored.png";
import redservi from "../../assets/Cash/Colombia/Payvalida/redservi.png";
import Sured from "../../assets/Cash/Colombia/Payvalida/Sured.png";
import SusuertePayvalida from "../../assets/Cash/Colombia/Payvalida/SusuertePayvalida.png";
import Facilito from "../../assets/Cash/Ecuador/Facilito/Facilito.png";
import WesternRedActiva from "../../assets/Cash/Ecuador/Facilito/WesternRedActiva.png";
import BancoGuayaquil from "../../assets/Cash/Ecuador/Safetypay/BancoGuayaquil.png";
import PagoAgil from "../../assets/Cash/Ecuador/Safetypay/PagoAgil.png";
import Pichincha from "../../assets/Cash/Ecuador/Safetypay/Pichincha.png";
import PichinchaMiVecino from "../../assets/Cash/Ecuador/Safetypay/PichinchaMiVecino.png";
import Servipagos from "../../assets/Cash/Ecuador/Safetypay/Servipagos.png";
import Tía from "../../assets/Cash/Ecuador/Safetypay/Tía.png";
import eleven from "../../assets/Cash/Mexico/Paycash/7eleven.png";
import BodegaAurrera from "../../assets/Cash/Mexico/Paycash/BodegaAurrera.png";
import Calimax from "../../assets/Cash/Mexico/Paycash/Calimax.png";
import CircleK from "../../assets/Cash/Mexico/Paycash/CircleK.png";
import ComercialMexicana from "../../assets/Cash/Mexico/Paycash/ComercialMexicana.png";
import CréditoFamiliar from "../../assets/Cash/Mexico/Paycash/CréditoFamiliar.png";
import Extra from "../../assets/Cash/Mexico/Paycash/Extra.png";
import Heb from "../../assets/Cash/Mexico/Paycash/Heb.png";
import PréstamoExpress from "../../assets/Cash/Mexico/Paycash/PréstamoExpress.png";
import Roma from "../../assets/Cash/Mexico/Paycash/Roma.png";
import Soriana from "../../assets/Cash/Mexico/Paycash/Soriana.png";
import SuperfarmaciasSantaMaría from "../../assets/Cash/Mexico/Paycash/SuperfarmaciasSantaMaría.png";
import SuperNorte from "../../assets/Cash/Mexico/Paycash/SuperNorte.png";
import SuperQ from "../../assets/Cash/Mexico/Paycash/SuperQ.png";
import BBVA from "../../assets/Cash/Peru/Payvalida/BBVA.png";
import BCP from "../../assets/Cash/Peru/Payvalida/BCP.png";
import BeValida from "../../assets/Cash/Peru/Payvalida/BeValida.png";
import CajaAqp from "../../assets/Cash/Peru/Payvalida/CajaAqp.png";
import CajaCusco from "../../assets/Cash/Peru/Payvalida/CajaCusco.png";
import CajaHyo from "../../assets/Cash/Peru/Payvalida/CajaHyo.png";
import CajaIca from "../../assets/Cash/Peru/Payvalida/CajaIca.png";
import CajaPiura from "../../assets/Cash/Peru/Payvalida/CajaPiura.png";
import CajaTacna from "../../assets/Cash/Peru/Payvalida/CajaTacna.png";
import CajaTrujillo from "../../assets/Cash/Peru/Payvalida/CajaTrujillo.png";
import Interbank from "../../assets/Cash/Peru/Payvalida/Interbank.png";
import Scotiabank from "../../assets/Cash/Peru/Payvalida/Scotiabank.png";
import WesternUnion from "../../assets/Cash/Peru/Payvalida/WesternUnion.png";
import BcoRipley from "../../assets/Cash/Peru/Safetypay/BcoRipley.png";
import Kasnet from "../../assets/Cash/Peru/Safetypay/Kasnet.png";
import Tambo from "../../assets/Cash/Peru/Safetypay/Tambo.png";
import { ProcessorsEnum } from "../infrastructure/ProcessorEnum";
import { CountryEnum } from "../infrastructure/CountryEnum";

export interface TCashBrands {
  image: string;
  value: string;
  processor: string;
  country: string;
}

export const AllBrandsCash: TCashBrands[] = [
  {
    country: CountryEnum.CHL,
    image: Acuenta,
    value: "Acuenta",
    processor: ProcessorsEnum.SENCILLITO,
  },
  {
    country: CountryEnum.CHL,
    image: CajaVecina,
    value: "CajaVecina",
    processor: ProcessorsEnum.SENCILLITO,
  },
  {
    country: CountryEnum.CHL,
    image: ExpressLider,
    value: "ExpressLider",
    processor: ProcessorsEnum.SENCILLITO,
  },
  {
    country: CountryEnum.CHL,
    image: Lider,
    value: "Lider",
    processor: ProcessorsEnum.SENCILLITO,
  },
  {
    country: CountryEnum.CHL,
    image: Sencillito,
    value: "CajaVecina",
    processor: ProcessorsEnum.SENCILLITO,
  },
  {
    country: CountryEnum.CHL,
    image: Serviestado,
    value: "Serviestado",
    processor: ProcessorsEnum.SENCILLITO,
  },
  {
    country: CountryEnum.CHL,
    image: Walmart,
    value: "Walmart",
    processor: ProcessorsEnum.SENCILLITO,
  },
  {
    country: CountryEnum.COL,
    image: BalotoPayvalida,
    value: "BalotoPayvalida",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Carulla,
    value: "Carulla",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Cerca,
    value: "Cerca",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Colsubsidio,
    value: "Colsubsidio",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: ConsuerteBcoBogota,
    value: "ConsuerteBcoBogotá",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Coopenessa,
    value: "Coopenessa",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Edeq,
    value: "Edeq",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: EfectyPayvalida,
    value: "EfectyPayvalida",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Exito,
    value: "Exito",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Fullcarga,
    value: "Fullcarga",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Jer,
    value: "Jer",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: La14,
    value: "La14",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Moviired,
    value: "Moviired",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Suchance,
    value: "Suchance",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: SuperInter,
    value: "SuperInter",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Surtimax,
    value: "Surtimax",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Surtimayorista,
    value: "Surtimayorista",
    processor: ProcessorsEnum.BANCO_BOGOTA,
  },
  {
    country: CountryEnum.COL,
    image: Apostar,
    value: "Apostar",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: BalotoPayvalida,
    value: "BalotoPayvalidaPay",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: Bancolombia,
    value: "Bancolombia",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: Dimonex,
    value: "Dimonex",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: EfectyPayvalida,
    value: "EfectyPayvalidaPay",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: Gana,
    value: "Gana",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: Puntored,
    value: "Puntored",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: redservi,
    value: "redservi",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: Sured,
    value: "Sured",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.COL,
    image: SusuertePayvalida,
    value: "SusuertePayvalida",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.ECU,
    image: Facilito,
    value: "Facilito",
    processor: ProcessorsEnum.FACILITO,
  },
  {
    country: CountryEnum.ECU,
    image: WesternRedActiva,
    value: "WesternRedActiva",
    processor: ProcessorsEnum.FACILITO,
  },
  {
    country: CountryEnum.ECU,
    image: BancoGuayaquil,
    value: "BancoGuayaquil",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.ECU,
    image: PagoAgil,
    value: "PagoAgil",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.ECU,
    image: Pichincha,
    value: "Pichincha",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.ECU,
    image: PichinchaMiVecino,
    value: "PichinchaMiVecino",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.ECU,
    image: Servipagos,
    value: "Servipagos",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.ECU,
    image: Tía,
    value: "Tía",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.MXN,
    image: eleven,
    value: "eleven",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: BodegaAurrera,
    value: "BodegaAurrera",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: Calimax,
    value: "Calimax",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: CircleK,
    value: "CircleK",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: ComercialMexicana,
    value: "ComercialMexicana",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: CréditoFamiliar,
    value: "CréditoFamiliar",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: Extra,
    value: "Extra",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: Heb,
    value: "Heb",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: PréstamoExpress,
    value: "PréstamoExpress",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: Roma,
    value: "Roma",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: Soriana,
    value: "Soriana",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: SuperfarmaciasSantaMaría,
    value: "SuperfarmaciasSantaMaría",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: SuperNorte,
    value: "SuperNorte",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: SuperQ,
    value: "SuperNorte",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.MXN,
    image: Walmart,
    value: "Walmart",
    processor: ProcessorsEnum.PAYCASH_PROCESSOR,
  },
  {
    country: CountryEnum.PER,
    image: BBVA,
    value: "BBVA",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: BCP,
    value: "BCP",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: BeValida,
    value: "BeValida",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: CajaAqp,
    value: "CajaAqp",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: CajaCusco,
    value: "CajaCusco",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: CajaHyo,
    value: "CajaHyo",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: CajaIca,
    value: "CajaIca",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: CajaPiura,
    value: "CajaPiura",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: CajaTacna,
    value: "CajaTacna",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: CajaTrujillo,
    value: "CajaTrujillo",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: Interbank,
    value: "Interbank",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: Scotiabank,
    value: "Scotiabank",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: WesternUnion,
    value: "WesternUnion",
    processor: ProcessorsEnum.PAYVALIDA,
  },
  {
    country: CountryEnum.PER,
    image: BBVA,
    value: "BBVA",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: BcoRipley,
    value: "BcoRipley",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: BCP,
    value: "BCP",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: CajaAqp,
    value: "CajaAqp",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: CajaHyo,
    value: "CajaHyo",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: CajaTacna,
    value: "CajaTacna",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: CajaTrujillo,
    value: "CajaTrujillo",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: Interbank,
    value: "Interbank",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: Kasnet,
    value: "Kasnet",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: Scotiabank,
    value: "Scotiabank",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: Tambo,
    value: "Tambo",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
  {
    country: CountryEnum.PER,
    image: WesternUnion,
    value: "WesternUnion",
    processor: ProcessorsEnum.SAFETY_PAY_CASH,
  },
];

export const getImagesByCountryAndProcessor = (
  country: string,
  processors: string[]
): TCashBrands[] => {
  const cashBrandImages: TCashBrands[] = [];
  AllBrandsCash.map((cashBrand) => {
    processors.map((processor, index) =>
      cashBrand.country === country && cashBrand.processor === processor
        ? cashBrandImages.push(cashBrand)
        : null
    );
  });
  return cashBrandImages.filter(
    (value: TCashBrands, index: number, attribute: TCashBrands[]) =>
      attribute.indexOf(value) === index
  );
};
