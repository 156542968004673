import { IAppTranslation } from "../infrastructure/MultiLanguage";

/**
 * English translation for webcheckout
 */
const notAvailableEnglishMessage: string = "Service not available";
const identificationNumber: string = "Identification Number";

export const english: IAppTranslation = {
  payButton: ["Go to pay "],
  payButtonWithMount: ["Pay "],
  pageNotFound: ["We can't find the page you are looking for."],
  footer: {
    contacts: [" Contact us"],
    messageFooter: [
      "By confirming a transaction from this portal, you have previously accepted the Terms and Conditions of ",
    ],
    help: ["Do you need help?"],
    messagePay: [
      "This payment is securely processed by Kushki, a Level 1 PCI payment provider.",
    ],
    readMore: [" Read more"],
    changeLanguage: ["Change language"],
  },
  confirmMessage: {
    title: ["Do you want to delete this information?"],
    message: [
      "If you delete this data, you will not be able to use it to make your payments quickly. You can save this information again by selecting the option ",
    ],
    cancel: ["Cancel"],
    ok: ["Delete data"],
    messageComplete: [
      "If you delete this data, you will not be able to use it to make your payments quickly. You can save this information again by selecting the option in the payment box.",
    ],
    messageSecond: [" in the payment box."],
  },
  WrongPasswordless: {
    dataVerification: ["Verification"],
    securityData: ["There have been too many unsuccessful attempts"],
    securityDataMessage: [
      "Verification could not be completed because you have failed too many attempts. Retry.",
    ],
    securityRememberMessage: [
      "Remember that the amount that was loaded or debited from your card will be reversed immediately.",
    ],
  },
  purchaseDetail: {
    title: ["Payment details"],
    totalToPay: ["Total amount"],
    item: ["Item"],
    information: ["Information"],
    breakdown: ["Details"],
    paymentDescription: ["Description"],
  },
  listPaymentMethods: {
    titlePayMethods: ["Payment methods"],
    titlePaySaved: ["These are your previously saved payment methods"],
    titleOtherPay: ["Other payment methods "],
    titleCardAndDebit: ["Debit or credit card"],
    titleCard: ["Credit Card"],
    titleDebit: ["Debit Card"],
    titleCash: ["Cash"],
    titleTransfer: ["Transfer"],
    titleCardDynamic: ["Credit or debit card"],
    expiredCardMessage: ["The card is expired"],
  },
  card: {
    titleCard: ["Card"],
  },
  languages: {
    en: ["English"],
    es: ["Spanish"],
    pt: ["Portuguese"],
  },
  messageTimeSession: ["This session expires in "],
  userName: ["Hello "],
  changeTypePay: ["Change your payment method"],
  checkInformationSave: [
    "Save your payment information for next purchases in stores linked to Kushki",
  ],
  termsConditions: ["Agree with terms and conditions"],
  messagePCI: [
    "Your information is safe and encripted, complying with the PCI DSS level 1 certification.",
  ],
  loadingTCard: {
    mainLine: ["Please wait until you get redirected to your bank's site. "],
    secondaryLine: ["Don't close or abandon this page."],
  },
  loadingCard: {
    mainLine: ["Please wait "],
    secondaryLine: ["We are processing your payment request"],
  },
  safetyPay: [
    "Check your email and click “Create payment order“ so you can receive the information required to make the transfer.",
  ],
  sessionExpired: [
    "Your session has expired due to time limit. Please, sign in again.",
  ],
  backStore: ["Return to the store"],
  sessionExpiredTitle: ["Session expired"],
  downloadConfirmation: ["Download confirmation/receipt"],
  helpKushkiVerification: [
    "We have made a charge to your card as HELP_KUSHKIVERIFICATION, which will be reimbursed inmediately. The amount has 3 digits, please enter them below:",
  ],
  verification: ["Verification"],
  confirmCode: ["Confirm"],
  securityData: ["We take care of your safety. "],
  securityDataMessage: [
    "A verification code has been sent to your email to confirm the purchase",
  ],
  securityDataMessageDelete: [
    "A verification code has been sent to your email. Enter the digits to delete the saved data.",
  ],
  agree: ["Agree with "],
  terms: ["Terms and Conditions"],
  license: ["KUSHKI SOFTWARE USE LICENSE TERMS AND CONDITIONS"],
  licenseMessage: [
    "This document contains the terms and conditions of the KUSHKI S.A. Software Use License. (hereinafter “KUSHKI”), which, upon being accepted by the applying company (hereinafter the LICENSOR), constitutes a commercial contract between the Parties. In this sense, we suggest that you carefully read the entire content of this document.",
  ],
  clause: ["CLAUSE ONE.- BACKGROUND"],
  clauseFirstMessage: [
    "KUSHKI is a company legally constituted under the laws of the Republic of Ecuador, whose business line is to provide auxiliary financial services activities, through the provision of comprehensive platform services as a technological solution, which allows, through agreements or alliances , to provide in favor of a certain network of affiliated establishments, a software licensing.",
  ],
  clauseSecondMessage: [
    "KUSHKI is the owner of the copyright on the computer program or software called Kushki Pagos (hereinafter “Software”) By resolution No. SB-DTL-2018-450, dated May 4, 2018, the Legal Procedures Directorate of the Superintendency of Banks, classified KUSHKI as an auxiliary services company of the public and private financial sectors, in the transactional and payment area. Through resolution No. BCE-DNRO-2018-198, dated August 14, 2018, the Central Bank of Ecuador, qualified KUSHKI to operate an auxiliary payment system. The LICENSOR has expressed its interest in acquiring the license to use the KUSHKI Software for its non-exclusive and non-transferable use, in order to provide a service to facilitate the processing of online payments from the LICENSOR's customers, by credit card.",
  ],
  headerGeneral: {
    titleHeader: ["Excellent"],
    subTitleHeader: ["Your purchase has been successful"],
  },
  headerStp: {
    titleHeader: ["Purchase awaiting payment"],
    subTitleHeader: [
      "Ingresa los siguientes datos al momento de hacer la transferencia en tu banco en línea",
    ],
  },
  headerCash: {
    titleHeader: ["Purchase awaiting payment"],
    subTitleHeader: [
      "Go to the branch of your nearest bank and make the payment with the information that we provide below.",
    ],
  },
  headerPreAuth: {
    titleHeader: ["Excellent"],
    subTitleHeader: [
      "Your transaction has been successful, you will receive a temporary charge on your card",
    ],
  },
  purchaseSummary: ["Order summary"],
  purchaseSummaryTable: {
    mount: ["MOUNT"],
    state: ["ESTATE"],
    product: ["PRODUCT"],
    ticketNumber: ["TICKET NUMBER"],
    businessName: ["BUSSINES NAME"],
    date: ["DATE"],
    name: ["NAME"],
    identification: ["IDENTIFICATION"],
    mail: ["MAIL"],
    bank: ["BANK"],
    cus: ["CUS"],
    clabeAccount: ["CLABE ACCOUNT"],
    orderNumber: ["ORDER NUMBER"],
  },
  changeLanguage: ["Change language"],
  anotherCard: ["Try with another card"],
  dataVerification: ["The validation has failed."],
  dataVerificationFailed: ["Data verification failed."],
  dataVerificationFailedMessage: ["We couldn't validate your information."],
  dataVerificationFMessage: [" The payment has been cancelled."],
  otpMessage: [
    "Review the notification sent by your bank and enter the digits of the charge or debit made to your card. For example, if the charge or debit is ",
  ],
  otpSecondMessage: [" you must enter those figures"],
  byExample: ["For example"],
  invalidCode: ["Invalid code"],
  tryAgain: ["Please, try again."],
  form: {
    postalCode: ["Postal Code"],
    country: ["Country"],
    countryCode: ["Country code"],
    names: ["Names"],
    surnames: ["surnames"],
    documentType: ["Document type"],
    documentNumber: ["Document number"],
    state: ["State / Province / Region"],
    city: ["City"],
    address: ["Main address"],
    secondaryAddress: ["Secondary address (Optional)"],
    celphone: ["Telephone / Mobile"],
    name: ["Name"],
    cardNumber: ["Card number"],
    mmyy: ["MM/YY"],
    identificationNumber: ["ID Number"],
    email: ["Email"],
    numberCardInvalid: ["* Invalid card number"],
    fieldRequired: ["* This field is required"],
    securityCode: ["security code"],
    emailInvalid: ["* Invalid email"],
    codeInvalid: ["Invalid code"],
    region: ["Region"],
    province: ["Province"],
    department: ["Department"],
    municipality: ["Municipality"],
    cvcInvalid: ["* Invalid CVC"],
    expirationDateIncorrect: [
      "* Expiration date can't be less than actual date",
    ],
    invalidDate: ["* Invalid Date"],
  },
  personType: ["Kind of person"],
  naturalPerson: ["Natural"],
  legalPerson: ["Legal"],
  payCash: ["Pay in cash"],
  payTransfer: ["Pay with transfer"],
  payCard: ["Pay with card"],
  subtotal: ["Subtotal"],
  total: ["Total"],
  taxes: ["Taxes"],
  commission: ["Commission"],
  backButton: ["Back to store"],
  generatingConfirmation: ["Generating confirmation"],
  emailRedirectForOTP: {
    title: ["Don't worry"],
    description: ["We have taken the necessary measures to protect your data."],
    message: [
      "It will not be possible to carry out operations from Kushki without your confirmation.",
    ],
  },
  returnButton: ["Go back"],
  enterInformation: [
    "Enter the following information to proceed with the payment",
  ],
  declineTransaction: {
    declineTitle: ["Declined transaction"],
    cashTitle: ["Something went wrong"],
    errorMessageSecurity: [
      "Please, make sure the data entered is correct and try again.",
    ],
    errorMessageTimeOut: [
      "The transaction has been declined. Please try again",
    ],
    errorMessageCash: [
      "No charge has been generated to your debit or credit card",
    ],
    errorMessageIncompleteTrx: [
      "The transaction could not be completed. Try again",
    ],
    errorMessageWebPay: ["The upload could not be completed."],
    errorThreeDSecureTitle: ["Validation Error"],
    errorThreeDSecureMessage: [
      "Transaction could not be completed due to a validation error. Try again.",
    ],
  },
  deferred: {
    checkLabel: ["Pay in installments"],
    typeDeferred: ["Type"],
    months: ["Fees"],
    monthsOfGrace: ["Months of Grace"],
  },
  payInstallmentsDisclaimer: {
    ecuadorMessage: [
      "The installments are bank- related, and the interest is managed by the issuing bank of each card.",
    ],
    colombiaMessage: [
      "The installments are bank- related, and the interest is managed by the issuing bank of each card.",
    ],
    peruMessage: [
      "The installments are bank- related, and the interest is managed by the issuing bank of each card.",
    ],
    chileMessage: [
      "The installments are bank- related, and the interest is managed by the issuing bank of each card.",
    ],
    mexicoMessage: [
      "The installments are bank- related, and the interest is managed by the issuing bank of each card.",
    ],
  },
  modalLoading: {
    titlePrincipal: ["Please wait while we redirect you to the payment page."],
    titleSecondary: ["Don't close this window."],
    subTitle: ["Please wait..."],
  },
  redirectTimerMessage: {
    start: ["You will be automatically redirected in "],
    end: [" seconds."],
  },
};
