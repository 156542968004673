import * as firebase from "firebase/app";
import "firebase/database";
import { environment } from "../environments/environment";

const config: object = {
  apiKey: environment.firebaseConfig.apiKey,
  authDomain: environment.firebaseConfig.authDomain,
  databaseURL: environment.firebaseConfig.databaseURL,
  projectId: environment.firebaseConfig.projectId,
  storageBucket: environment.firebaseConfig.storageBucket,
  messagingSenderId: environment.firebaseConfig.messagingSenderId,
};

export const databaseRef = () => {
  firebase.initializeApp(config);

  return firebase.database().ref();
};
