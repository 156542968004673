export enum ActionTypes {
  SET_BRANDS_LIST = "[MAIN] SET_BRANDS_LIST",
  SET_BRANDS = "[MAIN] SET_BRANDS",
  SET_DEFERRED_OPTIONS = "[MAIN] SET_DEFERRED_OPTIONS",
  RESET_DEFERRED_OPTIONS = "[MAIN] RESET_DEFERRED_OPTIONS",
  SET_WEBCHECKOUT = "[MAIN] SET_WEBCHECKOUT",
  SET_WEBCHECKOUT_EMAIL = "[MAIN] SET_WEBCHECKOUT_EMAIL",
  SET_COMMISSION = "[MAIN] SET_COMMISSION",
  SET_TIMER = "[MAIN] SET_TIMER",
  SET_LOADING = "[MAIN] SET_LOADING",
  SET_INITIAL_LOADING = "[MAIN] SET_INITIAL_LOADING",
  SET_BANKS = "[MAIN] SET_BANKS",
  SET_SETTINGS = "[MAIN] SET_SETTINGS",
  SET_RECEIPT = "[MAIN] SET_RECEIPT",
  SET_ACTIVATE_TIMER = "[MAIN] SET_ACTIVATE_TIMER",
  GET_ERROR_404 = "[MAIN] GET_ERROR_404",
  SET_SAVED_PAYMENT_METHODS = "[MAIN] SET_SAVED_PAYMENT_METHODS",
  SET_INFO_FIREBASE = "[MAIN] SET_INFO_FIREBASE",
  SET_TRANSFER_INFO = "[MAIN] SET_TRANSFER_INFO",
  GET_MERCHANT_CUSTOMIZATION_INFO = "[MAIN] GET_MERCHANT_CUSTOMIZATION_INFO",
  SET_HIDE_TIMER = "[MAIN] SET_HIDE_TIMER",
  GET_MERCHANT_SIFTSCIENCE = "[MAIN] GET_MERCHANT_SIFTSCIENCE",
  SHOW_SAVE_EMAIL_MODAL = "[MAIN] SHOW_SAVE_EMAIL_MODAL",
  HIDE_SAVE_EMAIL_MODAL = "[MAIN] HIDE_SAVE_EMAIL_MODAL",
  SET_MERCHANT_ID = "[MAIN] SET_MERCHANT_ID",
  SET_USER_BLOCKED = "[MAIN] SET_USER_BLOCKED",
  SET_MODAL_LOADING = "[MAIN] SET_MODAL_LOADING",
}
