import React, { FC, PropsWithChildren, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { CognitoErrorEnum } from "../infrastructure/CognitoErrorEnum";
import { get } from "lodash";
import { environment } from "../../environments/environment";

const AUTH = {
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: environment.userPoolId,
    APP_CLIENT_ID: environment.appClientId,
  },
};

const { createContext, useContext } = React;

interface IInitAuth {
  email: string;
  merchantName: string;
  firstName?: string;
}

export interface ICapturePasswordless {
  show: boolean;
  callback: (email: string) => any;
}

interface IAuthContext {
  initAuth: (params: IInitAuth, callback: (email: string) => any) => void;
  answerCustomChallenge: (
    code: string,
    onWrongCodeCallback: () => void,
    onThreeTimesErrorCallback: () => void
  ) => void;
  capturePasswordless?: {
    show: boolean;
    callback: (email: string) => any;
  };
  hidePasswordless: () => void;
  checkSession: (callback: (email?: string) => any, email?: string) => void;
}

export type TAuthContext = IAuthContext;
const AuthContext = createContext<TAuthContext>(undefined!);

type TAuthProvider = Partial<TAuthContext>;
export const AuthProvider: FC<TAuthProvider> = (
  props: PropsWithChildren<TAuthProvider>
) => {
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>();
  const [capturePasswordless, setCapturePasswordless] = useState<
    ICapturePasswordless | undefined
  >();

  useEffect(() => {
    configure();
  }, []);

  const configure = () => {
    Auth.configure({
      Auth: {
        mandatorySignId: true,
        region: AUTH.cognito.REGION,
        userPoolId: AUTH.cognito.USER_POOL_ID,
        userPoolWebClientId: AUTH.cognito.APP_CLIENT_ID,
      },
    });
  };

  const signUp = async (
    email: string,
    merchantName: string,
    firstName?: string,
    password?: string
  ) => {
    const userAttributes = {
      given_name: !!firstName ? firstName : undefined,
      preferred_username: email,
      "custom:merchant": merchantName,
    };

    const params = {
      username: email,
      password: password || getRandomString(),
      attributes: userAttributes,
    };

    return await Auth.signUp(params);
  };

  const signIn = async (email: string, password?: string) => {
    const cognitoUser: CognitoUser = await Auth.signIn(email, password);
    setCognitoUser(cognitoUser);

    return cognitoUser;
  };

  const answerCustomChallenge = async (
    code: string,
    onWrongCodeCallback: () => void,
    onThreeTimesErrorCallback: () => void
  ) => {
    try {
      await Auth.sendCustomChallengeAnswer(cognitoUser, code);
    } catch {
      onThreeTimesErrorCallback();
      return;
    }

    try {
      const session = await Auth.currentSession();

      session.getRefreshToken().getToken();
      setJWT(session.getAccessToken().getJwtToken());

      capturePasswordless!.callback(
        session.getAccessToken().payload["username"]
      );
    } catch {
      onWrongCodeCallback();
      return;
    }
  };

  const signOut = async () => {
    await Auth.signOut();
    unsetJWT();
  };

  const initAuth = async (
    params: IInitAuth,
    callback: (email: string) => any
  ) => {
    const { email, merchantName, firstName } = params;

    if (await validateSession(email)) {
      callback(email);
      return;
    }

    try {
      const password = getRandomString();
      await signUp(email, merchantName, firstName, password);
      const cognitoUser = await signIn(email, password);
      setJWT(
        cognitoUser.getSignInUserSession()!.getAccessToken().getJwtToken()
      );
      callback(email);
    } catch (e) {
      if (e.code === CognitoErrorEnum.UsernameExistsException)
        await authSignInPasswordless(email, callback);
    }
  };

  const authSignInPasswordless = async (
    email: string,
    callback: (email: string) => any
  ) => {
    await signIn(email);
    setCapturePasswordless({
      show: true,
      callback,
    });
  };

  const setJWT = (jwt: string) => {
    localStorage.setItem("jwt", jwt);
  };

  const unsetJWT = () => {
    localStorage.removeItem("jwt");
  };

  const validateSession = async (email?: string) => {
    try {
      const session = await Auth.currentSession();

      const currentUserInfo = await Auth.currentUserInfo();

      if (
        email &&
        currentUserInfo.attributes.email.toLowerCase() !== email.toLowerCase()
      ) {
        await signOut();
        return false;
      }

      setJWT(session.getAccessToken().getJwtToken());
      return true;
    } catch {
      return false;
    }
  };

  const hidePasswordless = () => {
    setCapturePasswordless({
      show: false,
      callback: get(capturePasswordless, "callback") || (() => true),
    });
  };

  const checkSession = async (
    callback: (email?: string) => any,
    email?: string
  ) => {
    if (await validateSession(email)) {
      const currentUserInfo = await Auth.currentUserInfo();
      callback(currentUserInfo.attributes.email);
      return;
    }

    callback();
  };

  const value: TAuthContext = {
    initAuth: props.initAuth || initAuth,
    answerCustomChallenge: props.answerCustomChallenge || answerCustomChallenge,
    capturePasswordless: props.capturePasswordless || capturePasswordless,
    hidePasswordless: props.hidePasswordless || hidePasswordless,
    checkSession: props.checkSession || checkSession,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};

const getRandomString = () => {
  const randomValues = new Uint8Array(30);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
};

const intToHex = (nr: number) => {
  return nr.toString(16).padStart(2, "0");
};
