import { Translate as T } from "react-localize-redux";
import React from "react";

export enum CountryEnum {
  ECUADOR = "Ecuador",
  COLOMBIA = "Colombia",
  PERU = "Peru",
  CHILE = "Chile",
  MEXICO = "Mexico",
}

export const PayInInstallmentsMessage: Record<string, JSX.Element> = {
  [CountryEnum.ECUADOR]: <T id="payInstallmentsDisclaimer.ecuadorMessage" />,
  [CountryEnum.COLOMBIA]: <T id="payInstallmentsDisclaimer.colombiaMessage" />,
  [CountryEnum.PERU]: <T id="payInstallmentsDisclaimer.peruMessage" />,
  [CountryEnum.CHILE]: <T id="payInstallmentsDisclaimer.chileMessage" />,
  [CountryEnum.MEXICO]: <T id="payInstallmentsDisclaimer.mexicoMessage" />,
};
