export enum LanguagesEnum {
  ES = "ES",
  BR = "BR",
  EN = "EN",
}

export const TEXT_ENUM_LANGUAGE: Record<string, string> = {
  [LanguagesEnum.ES]: "Pagar aquí ",
  [LanguagesEnum.EN]: "Pay ",
  [LanguagesEnum.BR]: "Vai pagar ",
};
