/**
 * ErrorMessageEnum
 */
export enum ErrorMessage {
  WCH006 = "declineTransaction.errorMessageSecurity",
  CASH = "declineTransaction.errorMessageCash",
  INCOMPLETE_TRX = "declineTransaction.errorMessageIncompleteTrx",
  WEBPAY_INCOMPLETE_TRX = "declineTransaction.errorMessageWebPay",
  DEFAULT = "declineTransaction.errorMessageTimeOut",
  THREE_D_SECURE = "declineTransaction.errorThreeDSecureMessage",
}
