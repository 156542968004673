import { IAppTranslation } from "../infrastructure/MultiLanguage";

/**
 * Spanish translation for webcheckout
 */
export const spanish: IAppTranslation = {
  payButton: ["Ir a pagar "],
  payButtonWithMount: ["Pagar aquí "],
  pageNotFound: ["No podemos encontrar la página que buscas"],
  footer: {
    help: ["¿Necesitas ayuda?"],
    contacts: [" Contáctanos"],
    messageFooter: [
      "Al confirmar una transacción desde este portal, has aceptado previamente los Términos y Condiciones de ",
    ],
    messagePay: [
      "Este pago es procesado de forma segura por Kushki, un proveedor de pagos PCI de nivel 1.",
    ],
    readMore: [" Leer más"],
    changeLanguage: ["Cambiar Idioma"],
  },
  confirmMessage: {
    title: ["¿Deseas eliminar estos datos?"],
    messageComplete: [
      "Si eliminas estos datos, no podrás utilizarlos para realizar tus pagos rápidamente. Podrás guardar nuevamente esta información seleccionando la opción en la cajita de pago.",
    ],
    message: [
      "Si eliminas estos datos, no podrás utilizarnos para realizar tus pagos rápidamente. Podrás guardar nuevamente esta información seleccionando la opción ",
    ],
    messageSecond: [" en la cajita de pago."],
    cancel: ["Cancelar"],
    ok: ["Eliminar Datos"],
  },
  purchaseDetail: {
    title: ["Resumen de pago"],
    totalToPay: ["Total a pagar"],
    item: ["Producto"],
    information: ["Información"],
    breakdown: ["Desglose"],
    paymentDescription: ["Descripción"],
  },
  listPaymentMethods: {
    titlePayMethods: ["Medios de pago"],
    titlePaySaved: ["Datos de pago guardados"],
    titleOtherPay: ["Otros medios de pago"],
    titleCardAndDebit: ["Tarjeta de Crédito o Débito"],
    titleCard: ["Tarjeta de Crédito"],
    titleDebit: ["Tarjeta de Débito"],
    titleCash: ["Efectivo"],
    titleTransfer: ["Transferencia"],
    titleCardDynamic: ["Tarjeta de crédito o débito"],
    expiredCardMessage: ["La tarjeta se encuentra vencida"],
  },
  card: {
    titleCard: ["Tarjeta"],
  },
  languages: {
    en: ["Inglés"],
    es: ["Español"],
    pt: ["Portugués"],
  },
  messageTimeSession: ["Esta sesión expira en "],
  userName: ["Hola "],
  changeTypePay: ["Cambiar medio de pago"],
  checkInformationSave: [
    "Guarda los datos de tu tarjeta para compras futuras en comercios integrado a Kushki",
  ],
  termsConditions: ["Acepte los términos y condiciones"],
  messagePCI: [
    "Tus datos están seguros y encriptados, cumplen con certificación PCI DSS Nivel 1. ",
  ],
  loadingTCard: {
    mainLine: ["Espera mientras te redirigimos a la página de tu banco."],
    secondaryLine: ["No cierres ni salgas de esta ventana."],
  },
  loadingCard: {
    mainLine: ["Un momento "],
    secondaryLine: ["Estamos procesando su pago"],
  },
  safetyPay: [
    "Ingresa tu e-mail y haz clic en “Generar orden de pago” para recibir los datos necesarios para realizar tu transferencia.",
  ],
  sessionExpired: [
    "Tu sesión ha expirado por límite de tiempo. Por favor, ingresa nuevamente",
  ],
  backStore: ["Regresar a la tienda"],
  sessionExpiredTitle: ["Sesión expirada"],
  downloadConfirmation: ["Descargar confirmación"],
  helpKushkiVerification: [
    "Hemos realizado un pequeño cargo a tu tarjeta como HELP_KUSHKI VERIFICACIÓN, que será reversado inmediatamente. El cargo realizado cuenta con tres (3) cifras, las cuales deberás ingresar a continuación:",
  ],
  verification: ["Verificación"],
  confirmCode: ["Confirmar código"],
  securityData: ["Nos preocupamos por la seguridad de tus datos. "],
  securityDataMessage: [
    "Hemos enviado  un código de verificación  a tu correo electrónico para confirmar la compra.",
  ],
  securityDataMessageDelete: [
    "Hemos enviado  un código de verificación  a tu correo electrónico. Ingresa los dígitos para eliminar los datos guardados.",
  ],
  agree: ["Acepte los "],
  terms: ["Términos y Condiciones"],
  license: ["TÉRMINOS Y CONDICIONES DE LICENCIA DE USO DE SOFTWARE KUSHKI"],
  licenseMessage: [
    "El presente documento contiene los términos y condiciones de la Licencia de Uso del Software de KUSHKI S.A. (en adelante “KUSHKI”),que, al ser aceptados por la empresa aplicante (en adelante el LICENCIANTE), constituye un contrato mercantil entre las Partes, en tal sentido sugerimos leer detenidamente el contenido íntegro del presente documento.",
  ],
  clause: ["CLÁUSULA PRIMERA .- ANTECEDENTES"],
  clauseFirstMessage: [
    "KUSHKI es una compañía legalmente constituida bajo las leyes de la República del Ecuador, cuyo giro de negocio es brindar actividades auxiliares de servicios financieros, por medio de la provisión de servicios integrales de plataforma como solución tecnológica, que permite, por medio de convenios o alianzas, proveer a favor de una determinada red de establecimientos afiliados, de un licenciamiento de software.",
  ],
  clauseSecondMessage: [
    "KUSHKI es titular de los derechos de autor sobre el programa de ordenador o software denominado Kushki Pagos (en adelante “Software”) Mediante resolución No. SB-DTL-2018-450, de fecha 4 de mayo de 2018, la Dirección de Trámite Legales de la Superintendencia de Bancos, calificó a KUSHKI como compañía de servicios auxiliares de los sectores financieros público y privado, en el área transaccional y de pago. Mediante resolución No. BCE-DNRO-2018-198, de fecha 14 de agosto de 2018, el Banco Central del Ecuador, calificó a KUSHKI para operar sistema auxiliar de pagos. El LICENCIANTE ha manifestado su interés de adquirir la licencia de uso del Software de KUSHKI para su uso no exclusivo e intransferible, a fin de brindar un servicio para facilitar el procesamiento de los pagos en línea de los clientes del LICENCIANTE, mediante tarjeta de crédito.",
  ],
  headerGeneral: {
    titleHeader: ["Excelente"],
    subTitleHeader: ["Tu compra se ha realizado con éxito"],
  },
  headerStp: {
    titleHeader: ["Compra en espera de pago"],
    subTitleHeader: [
      "Ingresa los siguientes datos al momento de hacer la transferencia en tu banco en línea",
    ],
  },
  headerCash: {
    titleHeader: ["Compra en espera de pago"],
    subTitleHeader: [
      "Acércate a la agencia de tu banco más cercano y realiza el pago con los datos que te brindamos a continuación.",
    ],
  },
  headerPreAuth: {
    titleHeader: ["Excelente"],
    subTitleHeader: [
      "Tu transacción se ha realizado con éxito, recibirás un cargo temporal en tu tarjeta",
    ],
  },
  purchaseSummary: ["Resumen de la compra"],
  purchaseSummaryTable: {
    mount: ["MONTO"],
    state: ["ESTADO"],
    product: ["PRODUCTO"],
    ticketNumber: ["NÚMERO DE TICKET"],
    businessName: ["RAZÓN SOCIAL"],
    date: ["FECHA"],
    name: ["NOMBRE"],
    identification: ["IDENTIFICACIÓN"],
    mail: ["MAIL"],
    bank: ["BANK"],
    cus: ["CUS"],
    clabeAccount: ["CUENTA CLABE"],
    orderNumber: ["N° DE ORDEN"],
  },
  changeLanguage: ["Cambiar"],
  anotherCard: ["Intenta con otra tarjeta"],
  dataVerification: ["Verificación de datos"],
  dataVerificationFailed: ["Verificación de datos fallida"],
  dataVerificationFailedMessage: [
    "No se ha podido realizar la verificación de tu información ",
  ],
  WrongPasswordless: {
    dataVerification: ["Verificación"],
    securityData: ["Se han producido demasiados intentos fallidos"],
    securityDataMessage: [
      "No se ha podido completar la verificación porque has fallado demasiados intentos. Vuelve a intentarlo.",
    ],
    securityRememberMessage: [
      "Recuerda que el monto que se cargó o debitó de tu tarjeta será reversado inmediatamente.",
    ],
  },
  dataVerificationFMessage: ["El pago ha sido cancelado. "],
  otpMessage: [
    "Revisa la notificación enviada por tu banco e ingresa las cifras del cargo o débito realizado a tu tarjeta. Por ejemplo, si el cargo o débito es ",
  ],
  otpSecondMessage: [" deberás ingresar las cifras"],
  byExample: ["Por ejemplo:"],
  invalidCode: ["Código inválido"],
  tryAgain: ["Intenta nuevamente"],
  form: {
    postalCode: ["Código Postal"],
    country: ["País"],
    countryCode: ["Código de País"],
    names: ["Nombres"],
    surnames: ["Apellidos"],
    documentType: ["Tipo de documento"],
    documentNumber: ["N° de documento"],
    state: ["Estado / Provincia / Región"],
    city: ["Ciudad"],
    address: ["Dirección principal"],
    secondaryAddress: ["Dirección secundaria ( Opcional )"],
    celphone: ["Télefono / Móvil"],
    name: ["Nombre y Apellido"],
    cardNumber: ["Número de tarjeta"],
    mmyy: ["MM/AA"],
    identificationNumber: ["Número de Identificación"],
    email: ["Email"],
    numberCardInvalid: ["* Número de tarjeta inválido"],
    fieldRequired: ["* Campo obligatorio"],
    securityCode: ["Código de seguridad"],
    emailInvalid: ["* Email inválido"],
    codeInvalid: ["Código inválido"],
    region: ["Región"],
    province: ["Provincia"],
    department: ["Departamento"],
    municipality: ["Municipio"],
    cvcInvalid: ["* CVC inválido"],
    expirationDateIncorrect: [
      "* Fecha de expiración no puede ser menor a la fecha actual",
    ],
    invalidDate: ["* Fecha Invalida"],
  },
  personType: ["Tipo de Persona"],
  legalPerson: ["Jurídica"],
  naturalPerson: ["Natural"],
  payCash: ["Pagar con Efectivo"],
  payTransfer: ["Pagar con transferencia"],
  payCard: ["Pagar con Tarjeta"],
  subtotal: ["Subtotal"],
  total: ["Total"],
  taxes: ["Impuestos"],
  commission: ["Comisión"],
  backButton: ["Volver al comercio"],
  generatingConfirmation: ["Generando confirmación"],
  emailRedirectForOTP: {
    title: ["No te preocupes"],
    description: [
      "Hemos tomado las medidas necesarias para proteger tus datos.",
    ],
    message: [
      "No será posible realizar operaciones desde Kushki sin tu confirmación.",
    ],
  },
  returnButton: ["Regresar"],
  enterInformation: ["Ingresa los siguientes datos para proceder con el pago"],
  declineTransaction: {
    declineTitle: ["Transacción declinada"],
    cashTitle: ["Algo salió mal"],
    errorMessageSecurity: [
      "Por favor, asegúrate que los datos ingresados son correctos y vuelve a intentarlo.",
    ],
    errorMessageTimeOut: [
      "La transacción ha sido declinada. Por favor, intenta nuevamente.",
    ],
    errorMessageCash: [
      "No se ha generado ningún cargo a tu tarjeta de débito o crédito",
    ],
    errorMessageIncompleteTrx: [
      "La transacción no se pudo completar. Intenta nuevamente.",
    ],
    errorMessageWebPay: ["La carga no se pudo completar."],
    errorThreeDSecureTitle: ["Error en la Validación"],
    errorThreeDSecureMessage: [
      "La transacción no se pudo completar por un error de validación. Intenta nuevamente.",
    ],
  },
  deferred: {
    checkLabel: ["Pagar en cuotas"],
    typeDeferred: ["Tipo"],
    months: ["Cuotas"],
    monthsOfGrace: ["Meses de Gracia"],
  },
  modalLoading: {
    titlePrincipal: ["Espera mientras te redirigimos a la página de pago."],
    titleSecondary: ["No cierres esta ventana."],
    subTitle: ["Por favor, espera..."],
  },
  payInstallmentsDisclaimer: {
    ecuadorMessage: [
      "Las cuotas son bancarias, el interés es administrado por el banco emisor de cada tarjeta.",
    ],
    colombiaMessage: [
      "Las cuotas son bancarias, el interés es administrado por el banco emisor de cada tarjeta.",
    ],
    peruMessage: [
      "Las cuotas son bancarias, el interés es administrado por el banco emisor de cada tarjeta.",
    ],
    chileMessage: [
      "Las cuotas son bancarias, el interés es administrado por el banco emisor de cada tarjeta.",
    ],
    mexicoMessage: [
      "Las cuotas son bancarias, el interés es administrado por el banco emisor de cada tarjeta.",
    ],
  },
  redirectTimerMessage: {
    start: ["En "],
    end: [" segundos será redireccionado al comercio."],
  },
};
